import { mapMutations, mapState } from 'vuex'
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin'
import{
    fetchTypesEmployee,
    createEmployeeType,
    updateEmployeeType,
    deleteEmployeeType
} from '../../helpers/KnEmployeeTypesOptions'
import { paginationMixin } from "@/modules/shared/mixins/paginationMixin";

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue'
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue' 
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue'

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin,validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      employeeType: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      employeeTypes: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    }
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedTypeEmployee(1);
    this.employeeType.id_autor = this.userData.id
    this.employeeType.id_institucion_educativa = this.institutionId
    //this.employeeTypes = await fetchEmployeeTypeByEI(this.institutionId)

  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar'
    },
    invalidFields() {
      return this.objectHasNulls(this.employeeType)
    }
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedTypeEmployee(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedTypeEmployee(1);
        this.employeeType.id_autor = this.userData.id
        this.employeeType.id_institucion_educativa = this.institutionId
      },
    },
  },
  methods: {
    ...mapMutations(["setIsLogin"]),
    async getPaginatedTypeEmployee(page = 1) {
      try {
        this.employeeTypes = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchTypesEmployee({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const employee of data) {
              this.employeeTypes.push({ ...employee });
            }
          } else {
            console.error(
              "No se pudieron obtener los tipos de empleados",
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error("Error al obtener los tipos de empleados:", error);
        this.loading = false;
      }
    },
    async save() {
      await createEmployeeType(this.employeeType.dato, this.institutionId)
      await this.getPaginatedTypeEmployee(1);
      //this.employeeTypes = await fetchEmployeeTypeByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.employeeType = {
        id: 'NA',
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.employeeType = {
        id: 'NA',
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      this.employeeType = {...item}
      await updateEmployeeType(this.employeeType.id, this.employeeType.dato, this.institutionId)
      await this.getPaginatedTypeEmployee(1);
      //this.employeeTypes = await fetchEmployeeTypeByEI(this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await deleteEmployeeType(itemId)
      await this.getPaginatedTypeEmployee(1);
      //this.employeeTypes = await fetchEmployeeTypeByEI(this.institutionId)
      this.clearInline()
    }
  },
}