<template>
  <section>
    <v-divider
      v-if="showDivider && dividerPosition === 'top'"
      class="my-8"
    ></v-divider>
    <v-row>
      <v-col cols="12">
        <h3 class="purple--text">{{ title }}</h3>
      </v-col>
    </v-row>
    <v-divider
      v-if="showDivider && dividerPosition === 'bottom'"
      class="my-8"
    ></v-divider>
    <div v-if="showDivider && dividerPosition === 'top'" class="mb-6"></div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    dividerPosition: {
      type: String,
      default: 'top',
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //
    };
  },
};
</script>

<style></style>
