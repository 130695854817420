import { api } from "@/api/axios-base";
import { getItem } from "@/api/api-methods";

export const postImage = async (formData) => {
  try {
    const response = await api.post("/app-personas/imagen", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
//Marcas
export const fetchBrands = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const dataFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/marca-producto?".concat(
        institutionFilter,
        dataFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchProductsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/marca-producto?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createBrand = async (
  imageFile,
  brandData,
  brandIE,
  brandIdAutor
) => {
  let result = null;
  try {
    // console.log("Imagen", imageFile);
    if (imageFile !== undefined && imageFile !== null) {
      const formData = new FormData();
      formData.append("imagen", imageFile);
      formData.append("nombre_imagen", "Imagen grupo");
      formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.post("/app-productos/marca-producto", {
      dato: brandData, //this.brand.dato,
      imagen: result === undefined || result === null ? null : result, //this.brand.ur_imagen,
      institucion_educativa: brandIE,
      autor: brandIdAutor, //this.brand.id_autor
    });
    if (response.errors) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear la marca", error);
    console.error("Error al intentar crear la marca");
  }
};
export const updateBrand = async (
  brandId,
  imageFile,
  brandData,
  brandIE,
  brandIdAutor
) => {
  let result = null;
  try {
    // console.log("Imagen", imageFile);
    if (imageFile !== undefined && imageFile !== null) {
      const formData = new FormData();
      formData.append("imagen", imageFile);
      formData.append("nombre_imagen", "Imagen grupo");
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.put(`/app-productos/marca-producto/${brandId}`, {
      dato: brandData, //this.brand.dato,
      imagen: result > 0 ? result : imageFile, //this.brand.ur_imagen,
      institucion_educativa: brandIE,
      autor: brandIdAutor, //this.brand.id_autor
    });
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        "Ocurrió un error, no se pudo actualizar la marca del producto"
      );
    } else {
      return responseData;
    }
  } catch (error) {
    console.error("Error al intentar actualizar la marca", error);
  }
};
export const deleteBrand = async (brandId) => {
  try {
    const response = await api.delete(
      `/app-productos/marca-producto/${brandId}`
    );
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        "Ocurrió un error, no se pudo eliminar la categoria de proveedor"
      );
    }
  } catch (error) {
    return undefined;
  }
};

//Códigos SAT
export const fetchSatCodeBy = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const dataFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/codigos-sat?".concat(
        institutionFilter,
        dataFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchSatCodeByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/codigos-sat?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createSATCode = async (
  satCodeData,
  satCodeDescription,
  institutionId,
  satCodeByIdAutor
) => {
  try {
    const response = await api.post("/app-productos/codigos-sat", {
      dato: satCodeData, //this.satCode.dato,
      descripcion: satCodeDescription, //this.satCode.descripcion,
      institucion_educativa: institutionId, //this.satCode.id_institucion_educativa,
      autor: satCodeByIdAutor, //this.satCode.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear el código SAT", error);
    console.error("Error al intentar crear el código SAT");
  }
};
export const updateSATCode = async (
  satCodeId,
  satCodeData,
  satCodeDescription,
  institutionId,
  satCodeByIdAutor
) => {
  try {
    const response = await api.put(`/app-productos/codigos-sat/${satCodeId}`, {
      dato: satCodeData, //this.satCode.dato,
      descripcion: satCodeDescription, //this.satCode.descripcion,
      institucion_educativa: institutionId, //this.satCode.id_institucion_educativa,
      autor: satCodeByIdAutor, //his.satCode.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar actualizar el código SAT", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteSATCode = async (satCodeId) => {
  try {
    const response = await api.delete(
      `/app-productos/codigos-sat/${satCodeId}`
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar eliminar el código SAT", error);
  }
};

//Categorías productos
export const fetchCategoryProduct = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const dataFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/categoria-producto?".concat(
        institutionFilter,
        dataFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchCategoryProductByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/categoria-producto?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createCategory = async (
  imageFile,
  categoryData,
  institutionId,
  categoryByAutor
) => {
  let result = null;
  try {
    if (imageFile !== undefined && imageFile !== null) {
      const formData = new FormData();
      formData.append("imagen", imageFile);
      formData.append("nombre_imagen", "Imagen grupo");
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.post("/app-productos/categoria-producto", {
      dato: categoryData, //this.category.dato,
      imagen: result === undefined || result === null ? null : result, //this.category.url_imagen,
      imagen_alt: result === undefined || result === null ? null : result,
      institucion_educativa: institutionId, //this.category.id_institucion_educativa,
      autor: categoryByAutor, //this.category.id_autor
    });

    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear la categoria de producto", error);
  }
};
export const updateCategory = async (
  categoryId,
  imageFile,
  categoryData,
  institutionId,
  categoryByAutor
) => {
  let result = null;
  try {
    if (imageFile !== undefined && imageFile !== null) {
      const formData = new FormData();
      formData.append("imagen", imageFile);
      formData.append("nombre_imagen", "Imagen grupo");
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.put(
      `/app-productos/categoria-producto/${categoryId}`,
      {
        dato: categoryData, //this.category.dato,
        imagen: result > 0 ? result : imageFile, //this.category.url_imagen,
        imagen_alt: result > 0 ? result : imageFile,
        institucion_educativa: institutionId, //this.category.id_institucion_educativa,
        autor: categoryByAutor, //this.category.id_autor
      }
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar actualizar la categoria de producto",
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteCategoryProduct = async (categoryId) => {
  try {
    const response = await api.delete(
      `/app-productos/categoria-producto/${categoryId}`
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar eliminar la categoria de producto", error);
  }
};

//SubCategorías productos
export const fetchSubCategorys = async ({
  dato = null,
  categoria = null,
  codigo_sat = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const dataFilter = dato ? `&dato=${dato}` : "";
    const categoryFilter = categoria ? `&categoria=${categoria}` : "";
    const codeSatFilter = codigo_sat ? `&codigo_sat=${codigo_sat}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/sub-categoria-producto?".concat(
        institutionFilter,
        dataFilter,
        categoryFilter,
        codeSatFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchSubCategorysByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/sub-categoria-producto?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createSubcategory = async (
  imageFile,
  subcategoryData,
  subCategoryByCategory,
  subcategoryByIdSat,
  institutionId,
  subcategoryByIdAutor
) => {
  let result = null;
  try {
    if (imageFile !== undefined && imageFile !== null) {
      const formData = new FormData();
      formData.append("imagen", imageFile);
      formData.append("nombre_imagen", "Imagen grupo");
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.post("/app-productos/sub-categoria-producto", {
      dato: subcategoryData, //this.subcategory.dato,
      categoria: subCategoryByCategory, //this.subcategory.id_categoria,
      codigo_sat: subcategoryByIdSat, //this.subcategory.id_codigo_sat,
      imagen: result === undefined || result === null ? null : result, //this.subcategory.url_imagen,
      institucion_educativa: institutionId, //this.subcategory.id_institucion_educativa,
      autor: subcategoryByIdAutor, //this.subcategory.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar crear la sub categoria de producto",
      error
    );
    console.error("Error al intentar crear la sub categoria de producto");
  }
};
export const updateSubCategory = async (
  subCategoryId,
  imageFile,
  subcategoryData,
  subCategoryByCategory,
  subcategoryByIdSat,
  institutionId,
  subcategoryByIdAutor
) => {
  let result = null;
  try {
    if (imageFile !== undefined && imageFile !== null) {
      const formData = new FormData();
      formData.append("imagen", imageFile);
      formData.append("nombre_imagen", "Imagen grupo");
      //formData.append('estatus_sistema', true);
      const response = await postImage(formData);
      result = response.id;
      if (!response) return response;
    }
    const response = await api.put(
      `/app-productos/sub-categoria-producto/${subCategoryId}`,
      {
        dato: subcategoryData, //this.subcategory.dato,
        categoria: subCategoryByCategory, //this.subcategory.id_categoria,
        codigo_sat: subcategoryByIdSat, //this.subcategory.id_codigo_sat,
        imagen: result > 0 ? result : imageFile, //this.subcategory.url_imagen,
        institucion_educativa: institutionId, //this.subcategory.id_institucion_educativa,
        autor: subcategoryByIdAutor, //this.subcategory.id_autor
      }
    );

    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar actualizar la sub categoria de producto",
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteSubCategory = async (subcategoryId) => {
  try {
    const response = await api.delete(
      `/app-productos/sub-categoria-producto/${subcategoryId}`
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar eliminar la sub categoria de producto",
      error
    );
  }
};

// Unidades de medida
export const fetchUOM = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const dataFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/uom?".concat(
        institutionFilter,
        dataFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchUnitByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/uom?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createUnit = async (unitData, institutionId, unitIdAutor) => {
  try {
    const response = await api.post("/app-productos/uom", {
      dato: unitData, //this.unit.dato,
      institucion_educativa: institutionId, //this.unit.id_institucion_educativa,
      autor: unitIdAutor, //this.unit.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear la unidad medida", error);
    console.error("Error al intentar crear la unidad medida");
  }
};
export const updateUnit = async (
  unitById,
  unitData,
  institutionId,
  unitIdAutor
) => {
  try {
    const response = await api.put(`/app-productos/uom/${unitById}`, {
      dato: unitData, //this.unit.dato,
      institucion_educativa: institutionId, //this.unit.id_institucion_educativa,
      autor: unitIdAutor, //this.unit.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar actualizar la unidad medida", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteUnit = async (unitId) => {
  try {
    const response = await api.delete(`/app-productos/uom/${unitId}`);
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar eliminar la unidad medida", error);
  }
};
// Backorders
export const fetchBackorder = async ({
  nombre = null,
  backorder = null,
  fabricante = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameFilter = nombre ? `&nombre=${nombre}` : "";
    const backorderFilter = backorder ? `&backorder=${backorder}` : "";
    const manufacturerFilter = fabricante ? `&fabricante=${fabricante}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/backorder?".concat(
        institutionFilter,
        nameFilter,
        backorderFilter,
        manufacturerFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchBackorderEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/backorder?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createBackorder = async (
  backorderName,
  backorder,
  backorderTime,
  backorderManufacturer,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.post("/app-productos/backorder", {
      nombre: backorderName, //this.backorder.nombre,
      backorder: backorder, //this.backorder.backorder,
      tiempo_entrega_dias: backorderTime, //this.backorder.tiempo_entrega_dias,
      fabricante: backorderManufacturer, //this.backorder.id_marca_producto,
      institucion_educativa: institutionId, //this.backorder.id_institucion_educativa,
      autor: idAutor, //this.backorder.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear el backorder", error);
    console.error("Error al intentar crear el backorder");
  }
};
export const updateBackorder = async (
  idBackOrder,
  backorderName,
  backorder,
  backorderTime,
  backorderManufacturer,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.put(`/app-productos/backorder/${idBackOrder}`, {
      nombre: backorderName, //this.backorder.nombre,
      backorder: backorder, //this.backorder.backorder,
      tiempo_entrega_dias: backorderTime, //this.backorder.tiempo_entrega_dias,
      fabricante: backorderManufacturer, //this.backorder.id_marca_producto,
      institucion_educativa: institutionId, //this.backorder.id_institucion_educativa,
      autor: idAutor, //this.backorder.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar actualizar el backorder", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteBackorder = async (backorderId) => {
  try {
    const response = await api.delete(
      `/app-productos/backorder/${backorderId}`
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar eliminar el backorder", error);
  }
};

//Tipo de opciones de producto
export const fetchTypeOptionProduct = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const dataFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/tipo-opcion-producto?".concat(
        institutionFilter,
        dataFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchTypeOptionProductEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/tipo-opcion-producto?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createOption = async (optionData, institutionId, idAutor) => {
  try {
    const response = await api.post("/app-productos/tipo-opcion-producto", {
      dato: optionData, //this.option.dato,
      institucion_educativa: institutionId, //this.option.id_institucion_educativa,
      autor: idAutor, //this.option.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar crear el tipo de opcion de producto",
      error
    );
    console.error("Error al intentar crear el tipo de opcion de producto");
  }
};
export const updateOption = async (
  optionProductById,
  optionData,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.put(
      `/app-productos/tipo-opcion-producto/${optionProductById}`,
      {
        dato: optionData, //this.option.dato,
        institucion_educativa: institutionId, //this.option.id_institucion_educativa,
        autor: idAutor, //this.option.id_autor
      }
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar actualizar el tipo de opcion de producto",
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteOption = async (optionId) => {
  try {
    const response = await api.delete(
      `/app-productos/tipo-opcion-producto/${optionId}`
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error(
      "Error al intentar eliminar el tipo de opcion de producto",
      error
    );
  }
};
//Opciones de producto
export const fetchOptionsProduct = async ({
  nombre_opcion = null,
  tipo_opcion = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameOptionFilter = nombre_opcion ? `&nombre_opcion=${nombre_opcion}` : "";
    const typeOptionFilter = tipo_opcion ? `&tipo_opcion=${tipo_opcion}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/opciones-producto?".concat(
        institutionFilter,
        nameOptionFilter,
        typeOptionFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchOptionsProductEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/opciones-producto?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createOptionProduct = async (
  optionsName,
  optionsDescription,
  optionsValues,
  typeOption,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.post("/app-productos/opciones-producto", {
      nombre_opcion: optionsName, //this.option.nombre_opcion,
      descripcion: optionsDescription, //this.option.descripcion,
      valor: optionsValues, //this.option.valor,
      tipo_opcion: typeOption, //this.option.id_tipo_opcion,
      institucion_educativa: institutionId, //this.option.id_institucion_educativa,
      autor: idAutor, //this.option.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear la opción", error);
    console.error("Error al intentar crear la opción");
  }
};
export const updateOptionProduct = async (
  idOptions,
  optionsName,
  optionsDescription,
  optionsValues,
  typeOption,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.put(
      `/app-productos/opciones-producto/${idOptions}`,
      {
        nombre_opcion: optionsName, //this.option.nombre_opcion,
        descripcion: optionsDescription, //this.option.descripcion,
        valor: optionsValues, //this.option.valor,
        tipo_opcion: typeOption, //this.option.id_tipo_opcion,
        institucion_educativa: institutionId, //this.option.id_institucion_educativa,
        autor: idAutor, //this.option.id_autor
      }
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar actualizar la opción", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteOptionProduct = async (optionId) => {
  try {
    const response = await api.delete(
      `/app-productos/opciones-producto/${optionId}`
    );
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar eliminar la opción", error);
  }
};
// Impuestos
export const fetchTaxes = async ({
  nombre_impuesto = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameTaxesFilter = nombre_impuesto ? `&nombre_impuesto=${nombre_impuesto}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-productos/filters/impuesto?".concat(
        institutionFilter,
        nameTaxesFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchTaxesEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-productos/filters/impuesto?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener sub categorias de proveedor por institucion educativa",
      error
    );
  }
};
export const createTax = async (nameTax, valueTax, institutionId, idAutor) => {
  try {
    const response = await api.post("/app-productos/impuesto", {
      nombre_impuesto: nameTax, //this.tax.nombre_impuesto,
      valor_impuesto: valueTax / 100, //this.tax.valor_impuesto / 100,
      institucion_educativa: institutionId, //this.tax.id_institucion_educativa,
      autor: idAutor, //this.tax.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar crear el impuesto", error);
    console.error("Error al intentar crear el impuesto");
  }
};
export const updateTax = async (
  idTaxData,
  nameTax,
  valueTax,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.put(`/app-productos/impuesto/${idTaxData}`, {
      nombre_impuesto: nameTax, //this.tax.nombre_impuesto,
      //valor_impuesto: valueTax,//this.tax.valor_impuesto / 100,
      valor_impuesto: valueTax / 100,
      institucion_educativa: institutionId, //this.tax.id_institucion_educativa,
      autor: idAutor, //this.tax.id_autor
    });
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar actualizar el impuesto", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteTax = async (taxId) => {
  try {
    const response = await api.delete(`/app-productos/impuesto/${taxId}`);
    if (response.error) {
      console.error(response.error);
    }
  } catch (error) {
    console.error("Error al intentar eliminar el impuesto", error);
  }
};
