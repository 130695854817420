import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchBrands,
  updateBrand,
  createBrand,
  deleteBrand,
} from '../../helpers/KnProductsOption';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnDocItem,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      brand: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      brands: [],
      toUpdate: false,
      imageFile: null,
      idImage: null,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedBrands(1);
    this.brand.id_autor = this.userData.id;
    this.brand.id_institucion_educativa = this.institutionId;
    //this.brands = await fetchProductsByEI(this.institutionId); //this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.brand);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedBrands(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedBrands(1);
        this.brand.id_autor = this.userData.id;
        this.brand.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedBrands(page = 1) {
      try {
        this.brands = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchBrands({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const brand of data) {
              this.brands.push({ ...brand });
            }
          } else {
            console.error('No se pudieron obtener las marcas', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las marcas:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        if (this.imageFile === undefined || this.imageFile == null) {
          await updateBrand(
            this.brand.id,
            this.idImage,
            this.brand.dato,
            this.institutionId,
            this.brand.id_autor
          );
          (this.imageFile = null), (this.idImage = null);
          this.clearInline();
          this.toUpdate = false;
          await this.getPaginatedBrands(1);
        } else {
          await updateBrand(
            this.brand.id,
            this.imageFile.file,
            this.brand.dato,
            this.institutionId,
            this.brand.id_autor
          );
          (this.imageFile = null), (this.idImage = null);
          this.clearInline();
          this.toUpdate = false;
          await this.getPaginatedBrands(1);
        }
      } else {
        if (this.imageFile === undefined || this.imageFile == null) {
          await createBrand(
            this.imageFile,
            this.brand.dato,
            this.institutionId,
            this.brand.id_autor
          );
          (this.imageFile = null), (this.idImage = null);
          this.clearInline();
        } else {
          await createBrand(
            this.imageFile.file,
            this.brand.dato,
            this.institutionId,
            this.brand.id_autor
          );
          (this.imageFile = null), (this.idImage = null);
        }
      }
      this.clearInline();
      await this.getPaginatedBrands(1);
    },
    clear() {
      this.brand = {
        id: 'NA', // No asignado
        dato: null,
        ur_imagen: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      (this.imageFile = null), (this.idImage = null), (this.toUpdate = false);
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.brand = {
        id: 'NA', // No asignado
        dato: null,
        ur_imagen: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      (this.imageFile = null), (this.idImage = null), (this.toUpdate = false);
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.brand = { ...item };
      this.brand.id_institucion_educativa = item.institucion_educativa.id;
      if (item.imagen !== undefined && item.imagen !== null) {
        this.imageFile = item.imagen.imagen;
        this.idImage = item.imagen.id;
      }
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteBrand(itemId);
      await this.getPaginatedBrands(1);
      this.clearInline();
    },
    saveImage(obj) {
      this.imageFile = obj;
    },
    clearImage() {
      this.imageFile = null;
    },
  },
};
