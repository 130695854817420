import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchEvaluationPeriods,
  createEvaluationPeriod,
  updateEvaluationPeriod,
  deleteEvaluationPeriod,
} from '../../helpers/KnGroupsOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      evaluationPeriod: {
        id: 'NA', // No asignado
        dato: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_institucion_educativa: null,
      },
      evaluationPeriods: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      console.log('Objeto', this.evaluationPeriod);
      return this.objectHasNulls(this.evaluationPeriod);
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedevaluationPeriods(1);
    this.evaluationPeriod.id_institucion_educativa = this.institutionId;
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedevaluationPeriods(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedevaluationPeriods(1);
        this.evaluationPeriod.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedevaluationPeriods(page = 1) {
      try {
        this.evaluationPeriods = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchEvaluationPeriods({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const evaluationPeriod of data) {
              this.evaluationPeriods.push({ ...evaluationPeriod });
            }
          } else {
            console.error(
              'No se pudieron obtener los periodos de evaluación',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los periodos de evaluación:', error);
        this.loading = false;
      }
    },

    async save() {
      if (this.toUpdate) {
        await updateEvaluationPeriod(
          this.evaluationPeriod.id,
          this.evaluationPeriod.dato,
          this.evaluationPeriod.fecha_inicio,
          this.evaluationPeriod.fecha_fin,
          this.evaluationPeriod.id_institucion_educativa
        );
        this.toUpdate = false;
        await this.getPaginatedevaluationPeriods(1);
      } else {
        await createEvaluationPeriod(
          this.evaluationPeriod.dato,
          this.evaluationPeriod.fecha_inicio,
          this.evaluationPeriod.fecha_fin,
          this.evaluationPeriod.id_institucion_educativa
        );
        this.clear();
        await this.getPaginatedevaluationPeriods(1);
      }
      this.clear();
    },
    clear() {
      this.evaluationPeriod = {
        id: 'NA', // No asignado
        dato: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.evaluationPeriod = {
        id: 'NA', // No asignado
        dato: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.evaluationPeriod = { ...item };
      // console.log("Item a editar:", this.evaluationPeriod);
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteEvaluationPeriod(itemId);
      await this.getPaginatedevaluationPeriods(1);
      this.clearInline();
    },
  },
};
