<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvcategoriaingreso')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorias de ingreso</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-income-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvestatusingreso')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Estatus de ingreso</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-income-status />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnIncomeCategories from '../components/KnIncomeCategories/KnIncomeCategories.vue';
import KnIncomeStatus from '../components/KnIncomeStatus/KnIncomeStatus.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    KnIncomeCategories,
    KnIncomeStatus,
  },
  methods: {
    canView: canView,
  },
};
</script>
