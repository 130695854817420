import { postItem } from "../../../api/api-methods"

export const fetchCreateCSD = async (CSDObj) => {
  try {
    const URL = '/app-facturacion/create-csd'
    const data  = await postItem(URL, CSDObj)
    return data
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}