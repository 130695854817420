import { mapState, mapMutations } from 'vuex';
import {
  fetchBanks,
  createBanks,
  updateBanks,
  deleteBanks,
} from '../../helpers/KnFinancialOptions';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      bank: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      banks: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedBanks(1);
    this.bank.id_autor = this.userData.id;
    this.bank.id_institucion_educativa = this.institutionId;
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar';
    },
    invalidFields() {
      return this.objectHasNulls(this.bank);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedBanks(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedBanks(1);
        this.bank.id_autor = this.userData.id;
        this.bank.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedBanks(page = 1) {
      try {
        this.banks = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchBanks({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const bank of data) {
              this.banks.push({ ...bank });
            }
          } else {
            console.error(
              'No se pudieron obtener los tipos de empleados',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los tipos de empleados:', error);
        this.loading = false;
      }
    },
    async save() {
      await createBanks(
        this.bank.dato,
        this.bank.id_autor,
        this.bank.id_institucion_educativa
      );
      await this.getPaginatedBanks(1);
      this.clear();
    },
    clear() {
      this.bank = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.bank = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      // console.log("updateInline item", item);
      this.bank = { ...item };
      await updateBanks(
        this.bank.id,
        this.bank.dato,
        this.bank.id_autor,
        this.bank.id_institucion_educativa
      );
      await this.getPaginatedBanks(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteBanks(itemId);
      await this.getPaginatedBanks(1);
      this.clearInline();
    },
  },
};
