import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchBankAccount,
  fetchMovementsByEI,
  fetchBanksByEI,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
} from '../../helpers/KnFinancialOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      bankAccount: {
        id: 'NA', // No asignado
        nombre_cuenta: null,
        numero_cuenta: null,
        balance: null,
        id_banco: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      selectedBankAccount: {},
      bankAccounts: [],
      banks: [],
      movements: [],
      toUpdate: false,
      movementsHeaders: [
        { text: 'Fecha', value: 'fecha_elaboracion' },
        { text: 'Movimiento', value: 'movimiento' },
        { text: 'Monto', value: 'monto' },
      ],
      showMovements: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedBankAccount(1);
    this.bankAccount.id_autor = this.userData.id;
    this.bankAccount.id_institucion_educativa = this.institutionId;
    this.banks = await fetchBanksByEI(this.institutionId);
    this.movements = await fetchMovementsByEI(this.institutionId);
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    bankActive() {
      if (this.banks && this.banks.results) {
        return this.banks.results.filter(
          (bank) => bank.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    accountMovements() {
      if (this.movements && this.movements.results) {
        return this.movements.results.filter(
          (movement) => movement.cuenta.id === this.selectedBankAccount.id
        );
      } else {
        return [];
      }
    },
    invalidFields() {
      return this.objectHasNulls(this.bankAccount);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedBankAccount(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedBankAccount(1);
        this.bankAccount.id_autor = this.userData.id;
        this.bankAccount.id_institucion_educativa = this.institutionId;
        this.banks = await fetchBanksByEI(this.institutionId);
        this.movements = await fetchMovementsByEI(this.institutionId);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedBankAccount(page = 1) {
      try {
        this.bankAccounts = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchBankAccount({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          if (ok) {
            this.setPaginationCount(count);
            for (const bankAccount of data) {
              this.bankAccounts.push({ ...bankAccount });
            }
          } else {
            console.error(
              'No se pudieron obtener los tipos de empleados',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los tipos de empleados:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateBankAccount(
          this.bankAccount.id,
          this.bankAccount.nombre_cuenta,
          this.bankAccount.numero_cuenta,
          this.bankAccount.balance,
          this.bankAccount.id_banco,
          this.bankAccount.id_autor,
          this.bankAccount.id_institucion_educativa
        );
        await this.getPaginatedBankAccount(1);
        this.toUpdate = false;
      } else {
        await createBankAccount(
          this.bankAccount.nombre_cuenta,
          this.bankAccount.numero_cuenta,
          this.bankAccount.balance,
          this.bankAccount.id_banco,
          this.bankAccount.id_autor,
          this.bankAccount.id_institucion_educativa
        );
        this.clear();
        await this.getPaginatedBankAccount(1);
      }
      this.clear();
    },
    clear() {
      this.bankAccount = {
        id: 'NA', // No asignado
        nombre_cuenta: null,
        numero_cuenta: null,
        balance: null,
        id_banco: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.bankAccount = { ...item };
      this.bankAccount.id_banco = item.banco.id;
      // console.log("Item a editar:", this.bankAccount);
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteBankAccount(itemId);
      await this.getPaginatedBankAccount(1);
      this.clear();
      this.bankAccount.id = null;
    },
    openMovements(item) {
      this.selectedBankAccount = { ...item };
      this.showMovements = true;
    },
    closeMovements() {
      this.selectedBankAccount = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.showMovements = false;
    },
  },
};
