import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import {
  updateOptionProduct,
  createOptionProduct,
  deleteOptionProduct,
  fetchOptionsProduct,
  fetchTypeOptionProductEI,
} from '../../helpers/KnProductsOption';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnPagination from '../../../shared/components/KnPagination.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnSelect,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      option: {
        id: 'NA', // No asignado
        nombre_opcion: null,
        descripcion: null,
        valor: null,
        id_tipo_opcion: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      options: [],
      types: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedOptionsProduct(1);
    this.option.id_autor = this.userData.id;
    this.option.id_institucion_educativa = this.institutionId;
    this.types = await fetchTypeOptionProductEI(this.institutionId);
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    activetypeOptions() {
      if (this.types && this.types.results) {
        return this.types.results.filter(
          (type) => type.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    invalidFields() {
      return this.objectHasNulls(this.option);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedOptionsProduct(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedOptionsProduct(1);
        this.option.id_autor = this.userData.id;
        this.option.id_institucion_educativa = this.institutionId;
        this.types = await fetchTypeOptionProductEI(this.institutionId);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedOptionsProduct(page = 1) {
      try {
        this.options = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchOptionsProduct({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const option of data) {
              this.options.push({ ...option });
            }
          } else {
            console.error(
              'No se pudieron obtener las opciones del producto',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las opciones del producto:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateOptionProduct(
          this.option.id,
          this.option.nombre_opcion,
          this.option.descripcion,
          this.option.valor,
          this.option.id_tipo_opcion,
          this.option.id_institucion_educativa,
          this.option.id_autor
        );
        this.toUpdate = false;
        await this.getPaginatedOptionsProduct(1);
      } else {
        await createOptionProduct(
          this.option.nombre_opcion,
          this.option.descripcion,
          this.option.valor,
          this.option.id_tipo_opcion,
          this.option.id_institucion_educativa,
          this.option.id_autor
        );
        this.clear();
        await this.getPaginatedOptionsProduct(1);
      }
      this.clear();
    },
    clear() {
      this.option = {
        id: 'NA', // No asignado
        nombre_opcion: null,
        valor: null,
        descripcion: null,
        id_tipo_opcion: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.option = {
        id: 'NA', // No asignado
        nombre_opcion: null,
        valor: null,
        descripcion: null,
        id_tipo_opcion: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.option = { ...item };
      this.option.id_tipo_opcion = item.tipo_opcion.id;
      this.option.id_institucion_educativa = item.institucion_educativa.id;
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteOptionProduct(itemId);
      await this.getPaginatedOptionsProduct(1);
      this.clearInline();
    },
  },
};
