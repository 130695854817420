<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvcategoriaproveedor')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorías de proveedor</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-supplier-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvsubcategoriaproveedor')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Sub categorías de proveedor</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-supplier-subcategories />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnSupplierCategories from '../components/KnSupplierCategories/KnSupplierCategories.vue';
import KnSupplierSubcategories from '../components/KnSupplierSubcategories/KnSupplierSubcategories.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    KnSupplierCategories,
    KnSupplierSubcategories,
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
