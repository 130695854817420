import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchCategoryProduct,
  createCategory,
  updateCategory,
  deleteCategoryProduct,
} from '../../helpers/KnProductsOption';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnPagination from '../../../shared/components/KnPagination.vue';

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnDocItem,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      category: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      categories: [],
      toUpdate: false,
      imageFile: null,
      idImage: null,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.category);
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedCategoryProduct(1);
    this.category.id_autor = this.userData.id;
    this.category.id_institucion_educativa = this.institutionId;
    //this.categories = await fetchCategoryProductByEI(this.institutionId);
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedCategoryProduct(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedCategoryProduct(1);
        this.category.id_autor = this.userData.id;
        this.category.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedCategoryProduct(page = 1) {
      try {
        this.categories = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchCategoryProduct({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const category of data) {
              this.categories.push({ ...category });
            }
          } else {
            console.error('No se pudieron obtener las categorias', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las categorias:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        if (this.imageFile === undefined || this.imageFile == null) {
          await updateCategory(
            this.category.id,
            this.idImage,
            this.category.dato,
            this.category.id_institucion_educativa,
            this.category.id_autor
          );
          await this.getPaginatedCategoryProduct(1);
        } else {
          await updateCategory(
            this.category.id,
            this.imageFile.file,
            this.category.dato,
            this.category.id_institucion_educativa,
            this.category.id_autor
          );
        }
        await this.getPaginatedCategoryProduct(1);

        this.toUpdate = false;
      } else {
        if (this.imageFile === undefined || this.imageFile == null) {
          await createCategory(
            this.imageFile,
            this.category.dato,
            this.category.id_institucion_educativa,
            this.category.id_autor
          );
          this.clear();
          await this.getPaginatedCategoryProduct(1);
        } else {
          await createCategory(
            this.imageFile.file,
            this.category.dato,
            this.category.id_institucion_educativa,
            this.category.id_autor
          );
          this.clear();
          await this.getPaginatedCategoryProduct(1);
        }
      }
      this.clear();
    },
    clear() {
      this.category = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.imageFile = null;
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.category = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.imageFile = null;
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.category = { ...item };
      // console.log("Item a editar:", this.category);
      this.category.id_institucion_educativa = item.institucion_educativa.id;
      if (item.imagen !== undefined && item.imagen !== null) {
        this.imageFile = item.imagen.imagen;
        this.idImage = item.imagen.id;
      }
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteCategoryProduct(itemId);
      await this.getPaginatedCategoryProduct(1);
      this.clearInline();
    },
    saveImage(obj) {
      this.imageFile = obj;
    },
    clearImage() {
      this.imageFile = null;
    },
  },
};
