import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchCoinsByEI,
  fetchWharehouseByEI,
  fetchMotivoMovimientoInventarioAll,
  fetchMvInventarioAll,
  fetchProductByEI,
  fetchMovimientoInventario,
  updateInventoryMovement,
  createInventoryMovement,
} from '../../helpers/knInventoryOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnSelect,
    KnTextField,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      movement: {
        id: 'NA', // No asignado
        producto: null,
        tipo_movimiento: null,
        motivo_movimiento: null,
        almacen: 1,
        inventario_inicial: null,
        cantidad_movimiento: null,
        inventario_final: null,
        precio_unitario: null,
        valor_movimiento: null,
        moneda: 1,
        autor: null,
        institucion_educativa: null,
      },
      selectedBankAccount: {},
      inventoryMovements: [],
      banks: [],
      movements: [],
      products: [],
      movementTypes: [],
      movementReasons: [],
      wharehouse: [],
      coins: [],
      toUpdate: false,
      movementsHeaders: [
        { text: 'Fecha', value: 'fecha_movimiento' },
        { text: 'Producto', value: 'producto.nombre_producto' },
        { text: 'Tipo', value: 'tipo_movimiento.dato' },
        { text: 'Motivo', value: 'motivo_movimiento.dato' },
        { text: 'Cantidad anterior', value: 'inventario_inicial' },
        { text: 'Cantidad movimiento', value: 'cantidad_movimiento' },
        { text: 'Cantidad final', value: 'inventario_final' },
        { text: 'Precio unitario', value: 'precio_unitario' },
        { text: 'Total', value: 'valor_movimiento' },
      ],
      showMovements: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedMovimientoInventario(1);
    this.movement.autor = this.userData.id;
    this.movement.institucion_educativa = this.institutionId;

    this.products = await fetchProductByEI(this.institutionId); //this.fetchResultsByEI('productos', 'producto', this.institutionId)
    this.movementTypes = await fetchMvInventarioAll(); //this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
    this.movementReasons = await fetchMotivoMovimientoInventarioAll(); //this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
    this.wharehouse = await fetchWharehouseByEI(this.institutionId); //this.fetchResultsByEI('inventarios', 'almacen', this.institutionId)
    this.coins = await fetchCoinsByEI(this.institutionId); //this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedMovimientoInventario(1);
        this.movement.autor = this.userData.id;
        this.movement.institucion_educativa = this.institutionId;
        this.products = await fetchProductByEI(this.institutionId); //this.fetchResultsByEI('productos', 'producto', this.institutionId)
        this.movementTypes = await fetchMvInventarioAll(); //this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
        this.movementReasons = await fetchMotivoMovimientoInventarioAll(); //this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
        this.wharehouse = await fetchWharehouseByEI(this.institutionId); //this.fetchResultsByEI('inventarios', 'almacen', this.institutionId)
        this.coins = await fetchCoinsByEI(this.institutionId); //this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.movement);
    },
    activeCoins() {
      if (this.coins && this.coins.results) {
        return this.coins.results.filter(
          (moneda) => moneda.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeWharehouse() {
      if (this.wharehouse && this.wharehouse.results) {
        return this.wharehouse.results.filter(
          (wharehouse) => wharehouse.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeReasonMovent() {
      if (this.movementReasons && this.movementReasons.results) {
        return this.movementReasons.results.filter(
          (movementReasons) => movementReasons.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeTypeMovent() {
      if (this.movementTypes && this.movementTypes.results) {
        return this.movementTypes.results.filter(
          (movementTypes) => movementTypes.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeProduct() {
      if (this.products && this.products.results) {
        return this.products.results.filter(
          (products) => products.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedMovimientoInventario(page = 1) {
      try {
        this.inventoryMovements = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchMovimientoInventario({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const inventoryMove of data) {
              this.inventoryMovements.push({ ...inventoryMove });
            }
          } else {
            console.error(
              'No se pudieron obtener los tipos de empleados',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los tipos de empleados:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateInventoryMovement(this.movement.id, this.movement);
        this.toUpdate = false;
        await this.getPaginatedMovimientoInventario(1);
      } else {
        await createInventoryMovement(this.movement);
        this.clear();
        await this.getPaginatedMovimientoInventario(1);
      }

      this.clear();
    },
    clear() {
      this.movement = {
        id: 'NA', // No asignado
        producto: null,
        tipo_movimiento: null,
        motivo_movimiento: null,
        almacen: 1,
        inventario_inicial: null,
        cantidad_movimiento: null,
        inventario_final: null,
        precio_unitario: null,
        valor_movimiento: null,
        moneda: 1,
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
  },
};
