import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnInterfaceColorThemeListItem from '../KnInterfaceColorThemeListItem/KnInterfaceColorThemeListItem.vue';
import KnInterfaceColorThemeEditor from '../KnInterfaceColorThemeEditor/KnInterfaceColorThemeEditor.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  emptyTheme,
  fetchThemes,
  delTheme,
  postTheme,
  putTheme,
  postSetJustOneActiveTheme,
} from '../../../shared/helpers/themeOptions';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  name: 'KnInterfaceColorTheme',
  components: {
    KnCheckBox,
    KnFormActionButtons,
    KnFormSubtitle,
    KnInterfaceColorThemeEditor,
    KnInterfaceColorThemeListItem,
    KnTextField,
  },
  mixins: [paginationMixin],
  data() {
    return {
      currentTheme: { ...emptyTheme },
      themes: [],
      loading: false,
      editing: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'theme']),
    title() {
      return this.editing ? 'Editar tema' : 'Crear tema';
    },
  },
  async created() {
    // this.setIsLogin(false);
    await this.getPaginatedThemes(1);
  },
  watch: {
    institutionId: {
      async handler() {
        await this.getPaginatedThemes(1);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    ...mapActions(['setTheme']),
    async getPaginatedThemes(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.themes = [];
        this.loading = true;
        const { ok, data, message, count } = await fetchThemes({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          this.themes = data;
        } else {
          console.error('Error al obtener temas. ', message);
        }
      }
      this.loading = false;
    },
    editTheme(item) {
      this.editing = true;
      // console.log('Tema a editar', item);
      this.currentTheme = item;
      this.currentTheme.institucion_educativa = item.institucion_educativa.id;
    },
    async deleteTheme(itemId) {
      // console.log('Tema a eliminar', itemId);
      const { ok, message } = await delTheme(itemId);
      if (ok) {
        // console.log('Tema eliminado exitosamente. ');
        await this.getPaginatedThemes(1);
      } else {
        console.log('No se pudo eliminar el tema. ', message);
      }
    },
    async createTheme() {
      const { ok, data, message } = await postTheme(this.currentTheme);
      if (ok) {
        // console.log('Tema creado exitosamente. ', data);
        this.setTheme(this.currentTheme);
        if (this.currentTheme.is_activo) {
          await this.setActiveTheme(data);
        }
      } else {
        console.log('No se pudo crear el tema. ', message);
      }
    },
    async updateTheme() {
      const { ok, message } = await putTheme(this.currentTheme);
      if (ok) {
        // console.log('Tema actualizado exitosamente. ', data);
        this.setTheme(this.currentTheme);
        if (this.currentTheme.is_activo) {
          await this.setActiveTheme(this.currentTheme);
        }
      } else {
        console.log('No se pudo actualizar el tema. ', message);
      }
    },
    async setActiveTheme(theme) {
      const { ok, data, message } = await postSetJustOneActiveTheme({
        institutionId: theme.institucion_educativa,
        themeId: theme.id,
      });
      if (ok) {
        console.log('Tema activado exitosamente. ', data);
      } else {
        console.log('No se pudo establecer tema activo. ', message);
      }
    },
    async save() {
      this.loading = true;
      if (this.editing) {
        await this.updateTheme();
      } else {
        this.currentTheme.institucion_educativa = this.institutionId;
        await this.createTheme();
      }
      this.loading = false;
      await this.getPaginatedThemes(1);
      this.cancel();
    },
    cancel() {
      this.editing = false;
      this.currentTheme = { ...emptyTheme };
    },
  },
};
