import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import KnMenu from '../../../shared/components/KnMenu.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import { fecthPutSubjectTeacher, fetchReactivateSubject, fetchRemoveSubject } from '../../helpers/subjectsOptions';
import { fetchDeleteCriterion, fetchPostCriterion } from '../../helpers/criterionOptions';
import { getItem } from '../../../../api/api-methods';

export default {
  components: { KnMenu, KnLocalAlert },
  mixins: [paginationMixin],
  data() {
    return {
      headers: [
        { text: "Materia", value: "materia.nombre" },
        { text: "Profesor", value: "profesor.datos_personales" },
        { text: "Fecha de Asignación", value: "fecha_asignacion" },
        { text: "Porcentaje Actual", value: "currentPercentage", sortable: false },
      ],
      items: [],
      search: null,
      alertPercentage: 'info',
      currentItem: null,
      fullWidth: false,
      showInactive: false,
      showBottomAction: false,
      hideDefaultFooter: true,
      loadingTable: false,
      // Alert component
      showAlert: false,
      alertText: "",
      alertType: '',
      alertColor: null,
      loading: false,
      errors: [],
    };
  },
  computed: {
    ...mapState(['currentPage', 'institutionId']),
    ...mapGetters(['tutorHasDebts', 'hasAdminPermission', 'employeeId', 'isTeacherEmploye']),
    filteredHeaders() {
      return this.headers.filter(header => {
        if (header.value === "profesor.datos_personales" && this.isTeacherEmploye) {
          return false;
        }
        return true;
      });
    },  
    bottomText() {
      return this.bottomActionText ? this.bottomActionText : `Ver ${this.tableTitle} inactivos`;
    },
    totalPercentage() {
      if (!this.currentItem || !this.currentItem.item.criterios_evaluacion) return 0;

      const total = this.currentItem.item.criterios_evaluacion
        .filter(criterio => !criterio.removed)
        .reduce((sum, criterio) => sum + parseFloat(criterio.porcentaje || 0), 0);

      this.alertPercentage = total === 100 ? 'success' : total > 100 ? 'error' : 'info';
      return total;
    }


  },
  watch: {
    paginationCount() {
      if (this.rememberLastPage) {
        this.page = this.currentPage || 1;
        this.setCurrentPage(this.currentPage);
      } else {
        this.page = 1;
      }
    },
    search: {
      async handler() {
        if (this.loadingTable) return;
        await this.searchSubject()
        console.log('Buscando');
      },
    },
    institutionId: {
      async handler() {
        await this.getSubjects(true);
      },
    },
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
    ...mapMutations(['setSearch', 'setSelectedId', 'setCurrentPage']),
    ...mapActions(['clearSearch']),

    async searchSubject() {
      await new Promise(resolve => setTimeout(resolve, 500));
      await this.getSubjects(!this.showInactive);
    },
    tableTitle() {
      return this.showInactive ? 'Materias inactivas' : 'Materias';
    },
    buttomActionText() {
      return this.showInactive
        ? 'Ver materias activas'
        : 'Ver materias inactivas';
    },
    goTo(routeName, params) {
      this.setSelectedId(params.entity.id);
      return this.$router.push({ name: routeName, params: { id: params.entity.id } });
    },
    //#region Comportamiento de la table 

    addCriterio(item) {
      item.criterios_evaluacion.push({
        id: Date.now(),
        nombre: '',
        porcentaje: undefined,
        recentlyAdded: true,
        removed: false
      });
    },


    async saveCriterios(item) {
      try {
        this.setLoadingState(true, `Iniciando el proceso de guardado de criterios para la materia ${item.materia.nombre}`, 'info');

        const criteriosEliminados = item.criterios_evaluacion.filter(criterio => criterio.removed);
        await Promise.all(criteriosEliminados.map(async (criterio) => {
          this.setLoadingState(true, `Eliminando criterio: ${criterio.nombre}`, 'info');
          const response = await fetchDeleteCriterion(criterio.id);

          if (response.ok) {
            this.setLoadingState(false, `Criterio "${criterio.nombre}" eliminado con éxito.`, 'success');
          } else {
            throw new Error(`Error al eliminar el criterio "${criterio.nombre}". Por favor, inténtelo nuevamente.`);
          }
        }));

        const savedCriteriaIds = await Promise.all(item.criterios_evaluacion.filter(criterio => !criterio.removed).map(async (criterio) => {
          this.setLoadingState(true, `Guardando criterio: ${criterio.nombre}`, 'info');

          const response = await fetchPostCriterion(criterio)

          if (response.ok) {
            this.setLoadingState(false, `Criterio "${criterio.nombre}" guardado con éxito.`, 'success');
            return response.data.id;
          } else {
            throw new Error(`Error al guardar el criterio "${criterio.nombre}". Por favor, verifique los datos e intente nuevamente.`);
          }
        }));

        this.setLoadingState(true, `Actualizando la información de la materia "${item.materia.nombre}" con los nuevos criterios...`, 'info');

        const { ok, error } = await fecthPutSubjectTeacher(item.id, {
          ...item,
          materia: item.materia.id,
          profesor: item.profesor.id,
          institucion_educativa: item.institucion_educativa.id,
          criterios_evaluacion: savedCriteriaIds,
        });

        if (!ok) {
          this.setErrorState(`No se pudo actualizar la información de la materia "${item.materia.nombre}". Error: ${error}. Por favor, inténtelo nuevamente.`);
        } else {
          await this.getSubjects();
          this.setSuccessState(`La información de la materia "${item.materia.nombre}" se actualizó correctamente con los nuevos criterios. Se guardaron ${savedCriteriaIds.length} criterios y se eliminaron ${criteriosEliminados.length}.`);
        }
      } catch (error) {
        console.error('Error durante el proceso de guardado:', error);
        this.setErrorState(`Ocurrió un error durante el guardado de criterios o la actualización de la materia "${item.materia.nombre}". Por favor, inténtelo nuevamente.`);
      }
    },

    removeCriterio(index, item) {
      const criterio = item.criterios_evaluacion[index];
      if (criterio.recentlyAdded) {
        item.criterios_evaluacion.splice(index, 1);
      } else {
        this.$set(item.criterios_evaluacion, index, {
          ...criterio,
          removed: true,
        });
      }
    },

    restoreCriterio(index, item) {
      const criterio = item.criterios_evaluacion[index];
      criterio.removed = false;
    },

    cancelChanges(item) {
      item.criterios_evaluacion = JSON.parse(JSON.stringify(item.originalCriterios)).map(criterio => {
        return { ...criterio, recentlyAdded: false, removed: false };
      });
      item.isExpanded = false;
    },

    toggleExpand(item) {
      item.expandLoading = true;
      if (!item || !item.item || !item.item.criterios_evaluacion) {
        console.error('El objeto item no está correctamente definido:', item);
        return;
      }
      this.currentItem = item;
      item.item.originalCriterios = JSON.parse(JSON.stringify(item.item.criterios_evaluacion));
      this.items.forEach((currentItem) => {
        if (currentItem.id !== item.id) {
          currentItem.isExpanded = false;
        }
      });
      item.isExpanded = !item.isExpanded;
      item.expandLoading = true;
    },
    calculatePercentage(item) {
      if (!item || !item.criterios_evaluacion) return 0;

      return item.criterios_evaluacion
        .filter(criterio => !criterio.removed) 
        .reduce((sum, criterio) => {
          return sum + parseFloat(criterio.porcentaje) || 0;
        }, 0);
    },
    getAlertType(item) {
      const total = this.calculatePercentage(item);
      return total === 100 ? 'success' : total > 100 ? 'error' : 'info';
    },
    //#endregion

    //#region  Form methods load etc
    async getSubjects(visible = true) {
      this.loadingTable = true;
      try {
        const limitFilter =
          this.pagination.limit !== null
            ? `&limit=${this.pagination.limit}`
            : '';
        const offsetFilter =
          this.pagination.offset !== 0
            ? `&offset=${this.pagination.offset}`
            : '';
        const searchFilter =
          this.search !== null ? `&materia_nombre=${this.search}` : '';
        const employeeFilter =
          this.isTeacherEmploye && this.employeeId
            ? `&profesor=${this.employeeId}`
            : '';

        const responseData = await getItem(
          `/app-personas/filters/materia-profesor?institucion_educativa=${this.institutionId}&estatus_sistema=${visible}${limitFilter}${offsetFilter}${searchFilter}${employeeFilter}`
        );
        this.setPaginationCount(responseData.count);
        await this.populateSubjectHolder(responseData.results);
        this.items = responseData.results;
      } catch (error) {
        console.error('Error al obtener materias:', error);
      } finally {
        this.loadingTable = false;
      }
    },
    async populateSubjectHolder(items) {
      const promises = items.map(async (subjectHolder) => {
        const { profesor } = subjectHolder;

        if (profesor && typeof profesor.datos_personales !== 'string') {
          const personalDataId = profesor.datos_personales;

          try {
            const personalData = await getItem(`app-personas/datos-personales/${personalDataId}`);
            subjectHolder.profesor.datos_personales = this.getFullName(personalData);
          } catch (error) {
            console.error(`Error fetching personal data for ID ${personalDataId}:`, error);
          }
        } else {
          subjectHolder.profesor = { datos_personales: 'Desconocido' };
        }
      });
      await Promise.all(promises);
    },

    getFullName(personalData) {
      const {
        primer_nombre,
        segundo_nombre,
        apellido_paterno,
        apellido_materno,
      } = personalData;
      const firstName = primer_nombre || '';
      const secondName = segundo_nombre || '';
      const lastName1 = apellido_paterno || '';
      const lastName2 = apellido_materno || '';
      return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
    },

    actionAlertBtn1() {
      this.closeAlert();
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    action2() {
      console.log('action2');
    },
    continueAdding() {
      console.log('continueAdding');
    },
    emitAction(value) {
      this.$emit('action', value);
    },
    async emitAction2(value) {
      try {
        let response;
        this.showInactive
          ? (response = await fetchReactivateSubject(value))
          : (response = await fetchRemoveSubject(value));

        if (response.ok) {
          await this.getSubjects(!this.showInactive);
        }
      } catch (error) {
        console.error('Error processing the request:', error);
      }
    },
    async emitShowAction(value) {
      this.clearSearch();
      this.resetPagination();
      await this.getSubjects(!value);
      this.showInactive = value;
    },
    async changePage(page) {
      this.setPaginationPage(page);
      await this.getSubjects();
    },
  },
  //#endregion
  async created() {
    await this.getSubjects(true);
  },
};
