import { api } from "@/api/axios-base";
import { getItem } from "@/api/api-methods";

//Egresos por categoria
export const fetchExpenseCategory = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datoFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/categoria-egreso?".concat(
        institutionFilter,
        datoFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los egresos por categoria. " + e,
        count: 0,
      };
    }
    // console.log('Estatus', results)
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener los egresos por categoria. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener los egresos por categoria. " + error,
      count: 0,
    };
  }
};
export const createExpenseCategory = async (
  expenseCategoriesData,
  expenseCategoriesAutor,
  expenseCategoriesIE
) => {
  try {
    const response = await api.post("/app-administracion/categoria-egreso", {
      dato: expenseCategoriesData, //this.expenseCategory.dato,
      autor: expenseCategoriesAutor, //this.expenseCategory.id_autor,
      institucion_educativa: expenseCategoriesIE, //this.expenseCategory.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.errors) {
      console.error("Ocurrió un error, no se pudo crear el tipo de gasto.");
    }
  } catch (error) {
    console.error("Error al intentar crear categoria de egreso", error);
    console.error("Error al intentar crear categoria de egreso");
  }
};
export const updateExpenseCategory = async (
  expenseCategoryId,
  expenseCategoriesData,
  expenseCategoriesAutor,
  expenseCategoriesIE
) => {
  try {
    const response = await api.put(
      `/app-administracion/categoria-egreso/${expenseCategoryId}`,
      {
        dato: expenseCategoriesData, //this.expenseCategory.dato,
        autor: expenseCategoriesAutor, //this.expenseCategory.id_autor,
        institucion_educativa: expenseCategoriesIE, //this.expenseCategory.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      console.error("Ocurrió un error, no se pudo actualizar el tipo de gasto");
    }
  } catch (error) {
    console.error("Error al intentar actualizar categoria de egreso", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteExpenseCategory = async (categoryId) => {
  try {
    const response = await api.delete(
      `/app-administracion/categoria-egreso/${categoryId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      console.error("Ocurrió un error, no se pudo eliminar el tipo de gasto");
    }
  } catch (error) {
    console.error("Error al intentar eliminar categoria de egreso", error);
  }
};

// estatus de egresos
export const fetchExpenseStatus = async ({
  proveedor = null,
  empleado = null,
  categoria = null,
  sub_categoria = null,
  forma_de_pago = null,
  moneda = null,
  cuenta_origen = null,
  estatus = null,
  centro_de_costos = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const proveedorFilter = proveedor ? `&proveedor=${proveedor}` : "";
    const empleadoFilter = empleado !== null ? `&empleado=${empleado}` : "";
    const categoriaFilter = categoria !== null ? `&categoria=${categoria}` : "";
    const sub_categoriaFilter =
      sub_categoria !== null ? `&sub_categoria=${sub_categoria}` : "";
    const cuenta_origenFilter =
      cuenta_origen !== null ? `&cuenta_origen=${cuenta_origen}` : "";
    const estatusFilter = estatus !== null ? `&estatus=${estatus}` : "";

    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const monedaFilter = moneda !== null ? `&moneda=${moneda}` : "";
    const forma_de_pagoFilter =
      forma_de_pago !== null ? `&forma_de_pago=${forma_de_pago}` : "";
    const centro_de_costosFilter =
      centro_de_costos !== null ? `&centro_de_costos=${centro_de_costos}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/estatus-egreso?".concat(
        institutionFilter,
        proveedorFilter,
        empleadoFilter,
        categoriaFilter,
        sub_categoriaFilter,
        cuenta_origenFilter,
        estatusFilter,
        autorFilter,
        monedaFilter,
        forma_de_pagoFilter,
        centro_de_costosFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los los egresos por estatus. " + e,
        count: 0,
      };
    }
    // console.log('Estatus', results)
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener los egresos por estatus. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener los egresos por estatus. " + error,
      count: 0,
    };
  }
};
export const createExpenseStatus = async (
  expenseStatusDato,
  autorId,
  institutionId
) => {
  try {
    const response = await api.post("/app-administracion/estatus-egreso", {
      dato: expenseStatusDato, //this.expenseStatus.dato,
      autor: autorId, //this.expenseStatus.id_autor,
      institucion_educativa: institutionId, //this.expenseStatus.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo crear el estatus de egreso."
      );
    }
  } catch (error) {
    console.error("Error al intentar crear estatus de egreso", error);
    this.errors.push("Error al intentar crear estatus de egreso");
  }
};
export const updateExpenseStatus = async (
  expenseStatusId,
  expenseStatusDato,
  autorId,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-administracion/estatus-egreso/${expenseStatusId}`,
      {
        dato: expenseStatusDato, //this.expenseStatus.dato,
        autor: autorId, //this.expenseStatus.id_autor,
        institucion_educativa: institutionId, //this.expenseStatus.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar el estatus de egreso"
      );
    }
  } catch (error) {
    console.error("Error al intentar actualizar estatus de egreso", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteExpenseStatus = async (typeId) => {
  try {
    const response = await api.delete(
      `/app-administracion/estatus-egreso/${typeId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo eliminar el estatus de egreso"
      );
    }
  } catch (error) {
    console.error("Error al intentar eliminar estatus de egreso", error);
  }
};

// SubCategoria egresos
export const fetchExpenseSubCategory = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datoFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/sub-categoria-egreso?".concat(
        institutionFilter,
        datoFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los egresos por Sub-categoria. " + e,
        count: 0,
      };
    }
    // console.log('Estatus', results)
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener los egresos por Sub-categoria. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener los egresos por Sub-categoria. " + error,
      count: 0,
    };
  }
};

export const createExpenseSubCategory = async (
  expenseSubCategoryDato,
  idAutor,
  institutionId
) => {
  try {
    const response = await api.post(
      "/app-administracion/sub-categoria-egreso",
      {
        dato: expenseSubCategoryDato, //this.expenseSubCategory.dato,
        autor: idAutor, //this.expenseSubCategory.id_autor,
        institucion_educativa: institutionId, //this.expenseSubCategory.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo crear la sub categoria.");
    }
  } catch (error) {
    console.error("Error al intentar crear sub categoria de egreso", error);
    this.errors.push("Error al intentar crear sub categoria de egreso");
  }
};
export const updateExpenseSubCategory = async (
  idSubCategoryExpense,
  expenseSubCategoryDato,
  idAutor,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-administracion/sub-categoria-egreso/${idSubCategoryExpense}`,
      {
        dato: expenseSubCategoryDato, //this.expenseSubCategory.dato,
        autor: idAutor, //this.expenseSubCategory.id_autor,
        institucion_educativa: institutionId, //this.expenseSubCategory.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar la sub categoria"
      );
    }
  } catch (error) {
    console.error(
      "Error al intentar actualizar sub categoria de egreso",
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteExpenseSubCategory = async (categoryId) => {
  try {
    const response = await api.delete(
      `/app-administracion/sub-categoria-egreso/${categoryId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo eliminar la sub categoria"
      );
    }
  } catch (error) {
    console.error("Error al intentar eliminar sub categoria de egreso", error);
  }
};
