import { mapState, mapMutations} from "vuex";
import {
  updateClassroom,
  createClassroom,
  deleteClassroom,
  fetchClassroom,
  fetchBranchesByEI
} from "../../helpers/KnGroupsOptions";
import { validationFormMixin } from "../../../shared/mixins/validationFormMixin";
import { paginationMixin } from "@/modules/shared/mixins/paginationMixin";
import KnFormSubtitle from "../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue";
import KnFormActionButtons from "../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue";
import KnPagination from "../../../shared/components/KnPagination.vue";

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      classroom: {
        id: "NA", // No asignado
        nombre_salon: null,
        ocupacion_maxima: null,
        id_sucursal: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      classrooms: [],
      branch: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["institutionId", "userData"]),
    actionTitle() {
      return this.toUpdate ? "Actualizar" : "Crear";
    },
    activeBranch() {
      if (this.branch && this.branch.results) {
        return this.branch.results.filter(
          (branchs) => branchs.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    invalidFields() {
      return this.objectHasNulls(this.classroom);
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedClassRoom(1);
    this.classroom.id_autor = this.userData.id;
    this.classroom.id_institucion_educativa = this.institutionId;
    this.branch = await fetchBranchesByEI(this.institutionId);
    //this.classrooms = await fetchClassroomByEI(this.institutionId);
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedClassRoom(1);
      },
    },
  },
  methods: {
    ...mapMutations(["setIsLogin"]),
    async getPaginatedClassRoom(page = 1) {
      try {
        this.classrooms = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchClassroom({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const classroom of data) {
              this.classrooms.push({ ...classroom });
            }
          } else {
            console.error(
              "No se pudieron obtener los salones de clases",
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error("Error al obtener los salones de clases:", error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateClassroom(
          this.classroom.id,
          this.classroom.nombre_salon,
          this.classroom.ocupacion_maxima,
          this.classroom.id_sucursal,
          this.classroom.id_autor,
          this.classroom.id_institucion_educativa
        );
        this.toUpdate = false;
        await this.getPaginatedClassRoom(1);

      } else {
        await createClassroom(
          this.classroom.nombre_salon,
          this.classroom.ocupacion_maxima,
          this.classroom.id_sucursal,
          this.classroom.id_autor,
          this.classroom.id_institucion_educativa
        );
        this.clear();
        await this.getPaginatedClassRoom(1);
      }
      //this.classrooms = await fetchClassroomByEI(this.institutionId);
      this.clear();
    },
    clear() {
      this.classroom = {
        id: "NA", // No asignado
        nombre_salon: null,
        ocupacion_maxima: null,
        id_sucursal: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.classroom = {
        id: "NA", // No asignado
        nombre_salon: null,
        ocupacion_maxima: null,
        id_sucursal: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.classroom = { ...item };
      this.classroom.id_sucursal = item.sucursal.id;
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteClassroom(itemId);
      await this.getPaginatedClassRoom(1);
      //this.classrooms = await fetchClassroomByEI(this.institutionId);
      this.clearInline();
    },
  },
};
