import { mapMutations, mapState } from "vuex";
import { validationFormMixin } from "../../../shared/mixins/validationFormMixin";
import {
  fetchTipoMovimientoInventario,
  deleteOption,
  updateOption,
  createOption,
} from "../../helpers/knInventoryOptions";
import KnFormSubtitle from "../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue";
import KnFormActionButtons from "../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue";
import KnListWithActions from "../../../shared/components/KnListWithActions/KnListWithActions.vue";
import KnTextField from "../../../shared/components/KnTextField.vue";
import { paginationMixin } from "@/modules/shared/mixins/paginationMixin";
export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      movType: {
        id: "NA", // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      movementTypes: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedInventoryMovementTypes(1);
    this.movType.id_autor = this.userData.id;
    this.movType.id_institucion_educativa = this.institutionId;
    //this.movementTypes = await fetchMvInventarioAll() //fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
  },
  computed: {
    ...mapState(["institutionId", "userData"]),
    actionTitle() {
      return this.toUpdate ? "Actualizar" : "Crear";
    },
    invalidFields() {
      return this.objectHasNulls(this.movType);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedInventoryMovementTypes(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedInventoryMovementTypes(1);
        this.movType.id_autor = this.userData.id;
        this.movType.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(["setIsLogin"]),
    async getPaginatedInventoryMovementTypes(page = 1) {
      try {
        this.movementTypes = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } =
            await fetchTipoMovimientoInventario({
              institutionId: this.institutionId,
              systemStatus: !this.showInactive,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });

          if (ok) {
            this.setPaginationCount(count);
            for (const movementType of data) {
              this.movementTypes.push({ ...movementType });
            }
          } else {
            console.error(
              "No se pudieron obtener los tipos de movimiento del inventario",
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error(
          "Error al obtener los tipos de movimiento del inventario:",
          error
        );
        this.loading = false;
      }
    },

    async save() {
      await createOption(this.movType.dato, this.movType.id_autor);
      await this.getPaginatedInventoryMovementTypes(1);
      this.clear();
    },
    clear() {
      this.movType = {
        id: "NA", // No asignado
        dato: null,
        id_autor: this.userData.id,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.movType = {
        id: "NA", // No asignado
        dato: null,
        id_autor: this.userData.id,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.movType = { ...item };
      await updateOption(
        this.movType.id,
        this.movType.dato,
        this.movType.id_autor
      );
      await this.getPaginatedExpenseStatus(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteOption(itemId);
      await this.getPaginatedInventoryMovementTypes(1);
      //this.movementTypes = await fetchMvInventarioAll() //fetchResultsAll('inventarios', 'mv-movimiento-i)nventario-all')
      this.clearInline();
    },
  },
};
