import { api } from '@/api/axios-base';
import { getItem } from "@/api/api-methods";

// Aquí empiezan las peticiones para las categorias de los proveedores
export const fetchSuplierCategory = async ({
  dato = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datoFilter = dato ? `&dato=${dato}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/categoria-proveedor?".concat(
        institutionFilter,
        datoFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los egresos por categoria. " + e,
        count: 0,
      };
    }
    // console.log('Estatus', results)
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener los egresos por categoria. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener los egresos por categoria. " + error,
      count: 0,
    };
  }
};
export const fetchSupplierCategoriesByEI = async (institutionId) => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-personas/filters/categoria-proveedor?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const createSupplierCategory = async (
  supplierCategoryData,
  supplierCategoryIe
) => {
  try {
    const response = await api.post('/app-personas/categoria-proveedor', {
      dato: supplierCategoryData,
      institucion_educativa: supplierCategoryIe,
    });
    const responseData = await response.data;
    if (responseData.e) {
      console.error(
        'Ocurrió un error, no se pudo crear la categoria de proveedor.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear categoria de proveedor', error);
    console.error('Error al intentar crear categoria de proveedor');
  }
};
export const updateSupplierCategory = async (
  supplierCategoryId,
  supplierCategoryDato,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/categoria-proveedor/${supplierCategoryId}`,
      {
        dato: supplierCategoryDato,
        institucion_educativa: institutionId,
      }
    );
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        'Ocurrió un error, no se pudo actualizar la categoria de proveedor'
      );
    } else {
      return responseData;
    }
  } catch (error) {
    return undefined;
  }
};
export const deleteSupplierCategory = async (typeId) => {
  try {
    const response = await api.delete(
      `/app-personas/categoria-proveedor/${typeId}`
    );
    const responseData = response.data;
    if (responseData.errors) {
      console.error(
        'Ocurrió un error, no se pudo eliminar la categoria de proveedor'
      );
    }
  } catch (error) {
    return undefined;
  }
};
// Aqui terminan las peticiones para las categorias del proveedor.

//Aquí empiezan las categorias para las subcategorias del proveedor.
export const fetchSuplierSubCategory = async ({
  dato = null,
  categoria = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datoFilter = dato ? `&dato=${dato}` : "";
    const categoryFilter = categoria ? `&categoria=${categoria}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/sub-categoria-proveedor?".concat(
        institutionFilter,
        datoFilter,
        categoryFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los egresos por categoria. " + e,
        count: 0,
      };
    }
    // console.log('Estatus', results)
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener los egresos por categoria. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener los egresos por categoria. " + error,
      count: 0,
    };
  }
};
/*export const fetchSupplierSubcategoriesByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/sub-categoria-proveedor?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener sub categorias de proveedor por institucion educativa',
      error
    );
  }
};*/
export const createSupplierSubcategory = async (
  subcategoryData,
  categoryId,
  institutionId
) => {
  try {
    const response = await api.post('/app-personas/sub-categoria-proveedor', {
      dato: subcategoryData,
      categoria: categoryId,
      institucion_educativa: institutionId,
    });
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        'Ocurrió un error, no se pudo crear la subcategoria de proveedor.'
      );
    }
  } catch (error) {
    console.error(
      'Error al intentar crear la subcategoria de proveedor',
      error
    );
    console.error('Error al intentar crear la subcategoria de proveedor');
  }
};
export const updateSupplierSubcategory = async (
  supplierSubCategoryId,
  supplierSubCategoryDato,
  supplierCategory,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/sub-categoria-proveedor/${supplierSubCategoryId}`,
      {
        dato: supplierSubCategoryDato,
        categoria: supplierCategory,
        institucion_educativa: institutionId,
      }
    );
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        'Ocurrió un error, no se pudo actualizar la categoria de proveedor'
      );
    } else {
      return responseData;
    }
  } catch (error) {
    return undefined;
  }
};
export const deleteSupplierSubcategory = async (subcategoryId) => {
  try {
    const response = await api.delete(
      `/app-personas/sub-categoria-proveedor/${subcategoryId}`
    );
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        'Ocurrió un error, no se pudo eliminar la categoria de proveedor'
      );
    }
  } catch (error) {
    return undefined;
  }
};
