<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <!-- <v-expansion-panel v-if="canView('sucursal')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Sucursales</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-sucursals />
        </v-expansion-panel-content>
      </v-expansion-panel> -->

      <v-expansion-panel v-if="canView('mvcicloescolar')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Ciclo escolar</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-school-cycle />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- <v-expansion-panel v-if="canView('salonclases')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Salones</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-classroom />
        </v-expansion-panel-content>
      </v-expansion-panel> -->

      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Grupos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-groups />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Materias</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnSubject/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Estatus calificación</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnQualificationStatus />
        </v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Periodo evaluación</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnEvaluationPeriod />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Criterio de evaluación</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnEvaluationCriteria
        /></v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Área propedéutica</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnPropaedeuticArea />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Nivel desempeño</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnPerformanceLevel />
        </v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Campo formativo</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnTrainingField
        /></v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Actividad extraescolar</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnExtracurricularActivity
        /></v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Nivel educativo</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnEducationalLevel />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- TODO: Agregar las opciones de multivaluados de control escolar -->
    </v-expansion-panels>
  </div>
</template>

<script>
// import KnGroupClassroom from '../components/KnGroupClassroom/KnGroupClassroom.vue';
// import KnGroupSucursals from '../components/KnGroupSucursals/KnGroupSucursals.vue';
// import KnPerformanceLevel from '../components/KnPerformanceLevel/KnPerformanceLevel.vue';
// import KnQualificationStatus from '../components/KnQualificationStatus/KnQualificationStatus.vue';
// import KnEvaluationCriteria from '../components/KnEvaluationCriteria/KnEvaluationCriteria.vue';
import KnGroupGroups from '../components/KnGroupGroups/KnGroupGroups.vue';
import KnSubject from '../components/KnSubject/KnSubject.vue';
import KnEvaluationPeriod from '../components/KnEvaluationPeriod/KnEvaluationPeriod.vue';
import KnPropaedeuticArea from '../components/KnPropaedeuticArea/KnPropaedeuticArea.vue';
import KnTrainingField from '../components/KnTrainingField/KnTrainingField.vue';
import KnExtracurricularActivity from '../components/KnExtracurricularActivity/KnExtracurricularActivity.vue';
import KnGroupSchoolCycle from '../components/KnGroupSchoolCycle/KnGroupSchoolCycle.vue';
import KnEducationalLevel from '../components/KnEducationalLevel/KnEducationalLevel.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    // KnGroupClassroom,
    // KnGroupSucursals,
    KnGroupGroups,
    KnEvaluationPeriod,
    KnPropaedeuticArea,
    KnGroupSchoolCycle,
    KnSubject,
    // KnQualificationStatus,
    // KnPerformanceLevel,
    // KnEvaluationCriteria,
    KnTrainingField,
    KnExtracurricularActivity,
    KnEducationalLevel,
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
