import { api } from "@/api/axios-base";
import { getItem } from "@/api/api-methods";

export const fetchBanks = async ({
  dato = null,
  autor = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datoFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/banco?".concat(
        institutionFilter,
        datoFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchBanksByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/banco?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener banco por institucion educativa",
      error
    );
  }
};
export const createBanks = async (bankData, idAutor, institutionId) => {
  try {
    const response = await api.post("/app-administracion/banco", {
      dato: bankData, //this.bank.dato,
      autor: idAutor, //this.bank.id_autor,
      institucion_educativa: institutionId, //this.bank.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo crear el banco.");
    }
  } catch (error) {
    console.error("Error al intentar crear banco", error);
    this.errors.push("Error al intentar crear banco");
  }
};
export const updateBanks = async (idBank, bankData, idAutor, institutionId) => {
  try {
    const response = await api.put(`/app-administracion/banco/${idBank}`, {
      dato: bankData, //this.bank.dato,
      autor: idAutor, //this.bank.id_autor,
      institucion_educativa: institutionId, //this.bank.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo actualizar el banco");
    }
  } catch (error) {
    console.error("Error al intentar actualizar banco", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteBanks = async (typeId) => {
  try {
    const response = await api.delete(`/app-administracion/banco/${typeId}`);
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo eliminar el banco");
    }
  } catch (error) {
    console.error("Error al intentar eliminar banco", error);
  }
};

//KnFinancialVarBankAccount
export const fetchBankAccount = async ({
  nombre_cuenta = null,
  numero_cuenta = null,
  banco = null,
  autor = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameAccountFilter = nombre_cuenta
      ? `&nombre_cuenta=${nombre_cuenta}`
      : "";
    const numberAccountFilter =
      numero_cuenta !== null ? `&numero_cuenta=${numero_cuenta}` : "";
    const bankFilter = banco !== null ? `&banco=${banco}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/cuenta?".concat(
        institutionFilter,
        nameAccountFilter,
        numberAccountFilter,
        bankFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchBankAccountByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/cuenta?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener cuentas bancarias por institucion educativa",
      error
    );
  }
};
export const fetchMovementsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/movimientos-cuenta?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener movimientos de cuentas bancarias por institucion educativa",
      error
    );
  }
};
export const createBankAccount = async (
  countName,
  countNumber,
  balance,
  bank,
  autor,
  institutionId
) => {
  try {
    const response = await api.post("/app-administracion/cuenta", {
      nombre_cuenta: countName, //this.bankAccount.nombre_cuenta,
      numero_cuenta: countNumber, //this.bankAccount.numero_cuenta,
      balance: balance, //this.bankAccount.balance,
      banco: bank, //this.bankAccount.id_banco,
      autor: autor, //this.bankAccount.id_autor,
      institucion_educativa: institutionId, //this.bankAccount.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo crear la cuenta bancaria."
      );
    }
  } catch (error) {
    console.error("Error al intentar crear cuenta bancaria", error);
    this.errors.push("Error al intentar crear cuenta bancaria");
  }
};
export const updateBankAccount = async (
  idBankAccount,
  countName,
  countNumber,
  balance,
  bank,
  autor,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-administracion/cuenta/${idBankAccount}`,
      {
        nombre_cuenta: countName, //this.bankAccount.nombre_cuenta,
        numero_cuenta: countNumber, //this.bankAccount.numero_cuenta,
        balance: balance, //this.bankAccount.balance,
        banco: bank, //this.bankAccount.id_banco,
        autor: autor, //this.bankAccount.id_autor,
        institucion_educativa: institutionId, //this.bankAccount.id_institucion_educativa
        /*id: this.bankAccount.id,
      nombre_cuenta: this.bankAccount.nombre_cuenta,
      numero_cuenta: this.bankAccount.numero_cuenta,
      balance: this.bankAccount.balance,
      id_banco: this.bankAccount.id_banco,
      id_institucion_educativa: this.institutionId*/
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar el tipo de gasto"
      );
    }
  } catch (error) {
    console.error("Error al intentar actualizar cuenta bancaria", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteBankAccount = async (accountId) => {
  try {
    const response = await api.delete(
      `/app-administracion/cuenta/${accountId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo eliminar la cuenta");
    }
  } catch (error) {
    console.error("Error al intentar eliminar cuenta bancaria", error);
  }
};

//PaymentMethod
export const fetchPaymentMethods = async ({
  dato = null,
  autor = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datotFilter = dato ? `&dato=${dato}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/forma-de-pago?".concat(
        institutionFilter,
        datotFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};

export const fetchPaymentMethodsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/forma-de-pago?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener forma de pago por institucion educativa",
      error
    );
  }
};
export const createPaymentMethods = async (
  paymentMethodDato,
  paymentMethodByIdAutor,
  institutionId
) => {
  try {
    const response = await api.post("/app-administracion/forma-de-pago", {
      dato: paymentMethodDato, //this.paymentMethod.dato,
      autor: paymentMethodByIdAutor, //this.paymentMethod.id_autor,
      institucion_educativa: institutionId, //this.paymentMethod.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo crear la forma de pago.");
    }
  } catch (error) {
    console.error("Error al intentar crear forma de pago", error);
    this.errors.push("Error al intentar crear forma de pago");
  }
};
export const updatePaymentMethods = async (
  idPaymenthMethod,
  paymentMethodDato,
  paymentMethodByIdAutor,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-administracion/forma-de-pago/${idPaymenthMethod}`,
      {
        dato: paymentMethodDato, //this.paymentMethod.dato,
        autor: paymentMethodByIdAutor, //this.paymentMethod.id_autor,
        institucion_educativa: institutionId, //this.paymentMethod.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar la forma de pago"
      );
    }
  } catch (error) {
    console.error("Error al intentar actualizar forma de pago", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deletePaymentMethods = async (typeId) => {
  try {
    const response = await api.delete(
      `/app-administracion/forma-de-pago/${typeId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo eliminar la forma de pago"
      );
    }
  } catch (error) {
    console.error("Error al intentar eliminar forma de pago", error);
  }
};

// Penalizaciones
export const fetchPenaltiys = async ({
  nombre = null,
  autor = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameFilter = nombre ? `&nombre=${nombre}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/penalizacion?".concat(
        institutionFilter,
        nameFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};

export const fetchPenalties = async () => {
  try {
    const response = await api.get("/app-administracion/penalizacion");
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error("Error al intentar obtener penalizaciones", error);
  }
};
export const fetchPenaltyByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/penalizacion?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener penalizaciones por institucion educativa",
      error
    );
  }
};
export const createPenalty = async (
  namePenality,
  penalityDescription,
  penalityaMount,
  penalityByIdAutor,
  institutionId
) => {
  try {
    const response = await api.post("/app-administracion/penalizacion", {
      nombre: namePenality, //this.penalty.nombre,
      descripcion: penalityDescription, //this.penalty.descripcion,
      monto: penalityaMount, //this.penalty.monto,
      autor: penalityByIdAutor, //this.penalty.id_autor,
      institucion_educativa: institutionId, //this.penalty.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo crear la penalización.");
    }
  } catch (error) {
    console.error("Error al intentar crear penalizacion", error);
    this.errors.push("Error al intentar crear penalizacion");
  }
};
export const updatePenalty = async (
  idPenality,
  namePenality,
  penalityDescription,
  penalityaMount,
  penalityByIdAutor,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-administracion/penalizacion/${idPenality}`,
      {
        nombre: namePenality, //this.penalty.nombre,
        descripcion: penalityDescription, //this.penalty.descripcion,
        monto: penalityaMount, //this.penalty.monto,
        autor: penalityByIdAutor, //this.penalty.id_autor,
        institucion_educativa: institutionId, //this.penalty.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar la penalización"
      );
    }
  } catch (error) {
    console.error("Error al intentar actualizar penalizacion", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deletePenalty = async (penaltyId) => {
  try {
    const response = await api.delete(
      `/app-administracion/penalizacion/${penaltyId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo eliminar la penalización");
    }
  } catch (error) {
    console.error("Error al intentar eliminar penalizacion", error);
  }
};

//Descuentos pronto pago
export const fetchPromptPaymentDiscounts = async ({
  nombre_descuento = null,
  fecha_inicio = null,
  fecha_fin = null,
  autor = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameFilter = nombre_descuento
      ? `&nombre_descuento=${nombre_descuento}`
      : "";
    const dateStartFilter = fecha_inicio ? `&fecha_inicio=${fecha_inicio}` : "";
    const dateEndFilter = fecha_fin ? `&fecha_fin=${fecha_fin}` : "";
    const autorFilter = autor !== null ? `&autor=${autor}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-administracion/filters/descuento-pronto-pago?".concat(
        institutionFilter,
        dateStartFilter,
        dateEndFilter,
        nameFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchPromptPaymentDiscountsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/descuento-pronto-pago?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener descuentos pronto pago por institucion educativa",
      error
    );
  }
};
export const createPromptPaymentDiscount = async (
  paymentDiscountName,
  paymentDiscountPercentage,
  paymentDiscountFirstDate,
  paymentDiscountEndDate,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.post(
      "/app-administracion/descuento-pronto-pago",
      {
        nombre_descuento: paymentDiscountName, //this.promptPaymentDiscount.nombre_descuento,
        porcentaje_descuento: paymentDiscountPercentage, //(this.promptPaymentDiscount.porcentaje_descuento / 100),
        fecha_inicio: paymentDiscountFirstDate, //this.promptPaymentDiscount.fecha_inicio,
        fecha_fin: paymentDiscountEndDate, //this.promptPaymentDiscount.fecha_fin,
        institucion_educativa: institutionId, //this.promptPaymentDiscount.id_institucion_educativa,
        autor: idAutor, //this.promptPaymentDiscount.id_autor
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo crear el descuento pronto pago."
      );
    }
  } catch (error) {
    console.error("Error al intentar crear el descuento pronto pago", error);
    this.errors.push("Error al intentar crear el descuento pronto pago");
  }
};
export const updatePromptPaymentDiscount = async (
  idPaymentDiscount,
  paymentDiscountName,
  paymentDiscountPercentage,
  paymentDiscountFirstDate,
  paymentDiscountEndDate,
  institutionId,
  idAutor
) => {
  try {
    const response = await api.put(
      `/app-administracion/descuento-pronto-pago/${idPaymentDiscount}`,
      {
        nombre_descuento: paymentDiscountName, //this.promptPaymentDiscount.nombre_descuento,
        porcentaje_descuento: paymentDiscountPercentage, //(this.promptPaymentDiscount.porcentaje_descuento / 100),
        fecha_inicio: paymentDiscountFirstDate, //this.promptPaymentDiscount.fecha_inicio,
        fecha_fin: paymentDiscountEndDate, //this.promptPaymentDiscount.fecha_fin,
        institucion_educativa: institutionId, //this.promptPaymentDiscount.id_institucion_educativa,
        autor: idAutor, //this.promptPaymentDiscount.id_autor
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar el descuento pronto pago"
      );
    }
  } catch (error) {
    console.error(
      "Error al intentar actualizar el descuento pronto pago",
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deletePromptPaymentDiscount = async (promptPaymentDiscountId) => {
  try {
    const response = await api.delete(
      `/app-administracion/descuento-pronto-pago/${promptPaymentDiscountId}`
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo eliminar el descuento pronto pago"
      );
    }
  } catch (error) {
    console.error("Error al intentar eliminar el descuento pronto pago", error);
  }
};

// Colegiaturas
export const fetchTuitions = async ({
  nombre_colegiatura = null,
  moneda = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const nameTutionsFilter = nombre_colegiatura
      ? `&nombre_colegiatura=${nombre_colegiatura}`
      : "";
    const currencyFilter = moneda ? `&moneda=${moneda}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/colegiatura?".concat(
        institutionFilter,
        nameTutionsFilter,
        currencyFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};
export const fetchTuitionsByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/colegiatura?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener descuentos pronto pago por institucion educativa",
      error
    );
  }
};
export const fetchCoinsByIE = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-administracion/filters/moneda?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error("Error al obtener monedas por institucion educativa", error);
  }
};
export const createTuition = async (
  CollegiateName,
  description,
  amount,
  coins,
  institutionId
) => {
  try {
    const response = await api.post("/app-personas/colegiatura", {
      nombre_colegiatura: CollegiateName, //this.tuition.nombre_colegiatura,
      descripcion: description, //this.tuition.descripcion,
      monto: amount, //this.tuition.monto,
      moneda: coins, //this.tuition.id_moneda,
      institucion_educativa: institutionId, //this.tuition.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo crear la colegiatura.");
    }
  } catch (error) {
    console.error("Error al intentar crear la colegiatura", error);
    this.errors.push("Error al intentar crear la colegiatura");
  }
};
export const updateTuition = async (
  idCollegiate,
  CollegiateName,
  description,
  amount,
  coins,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/colegiatura/${idCollegiate}`,
      {
        nombre_colegiatura: CollegiateName, //this.tuition.nombre_colegiatura,
        descripcion: description, //this.tuition.descripcion,
        monto: amount, //this.tuition.monto,
        moneda: coins, //this.tuition.id_moneda,
        institucion_educativa: institutionId, //this.tuition.id_institucion_educativa
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        "Ocurrió un error, no se pudo actualizar la colegiatura"
      );
    }
  } catch (error) {
    console.error("Error al intentar actualizar la colegiatura", error);
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteTuition = async (tuitionId) => {
  try {
    const response = await api.delete(`/app-personas/colegiatura/${tuitionId}`);
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push("Ocurrió un error, no se pudo eliminar la colegiatura");
    }
  } catch (error) {
    console.error("Error al intentar eliminar la colegiatura", error);
  }
};
