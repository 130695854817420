import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchPropaedeuticArea,
  createPropaedeuticArea,
  updatePropaedeuticArea,
  deletePropaedeuticArea,
} from '../../helpers/KnGroupsOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      propaedeuticArea: {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        id_institucion_educativa: null,
      },
      propaedeuticAreas: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.propaedeuticArea);
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatPropaedeuticArea(1);
    this.propaedeuticArea.id_institucion_educativa = this.institutionId;
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatPropaedeuticArea(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatPropaedeuticArea(1);
        this.propaedeuticArea.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatPropaedeuticArea(page = 1) {
      try {
        this.propaedeuticAreas = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchPropaedeuticArea({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const propaedeuticArea of data) {
              this.propaedeuticAreas.push({ ...propaedeuticArea });
            }
          } else {
            console.error(
              'No se pudieron obtener las areas prepadéuticas',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las areas prepadéuticas:', error);
        this.loading = false;
      }
    },

    async save() {
      if (this.toUpdate) {
        await updatePropaedeuticArea(
          this.propaedeuticArea.id,
          this.propaedeuticArea.nombre,
          this.propaedeuticArea.descripcion,
          this.institutionId
        );
        this.toUpdate = false;
        await this.getPaginatPropaedeuticArea(1);
      } else {
        await createPropaedeuticArea(
          this.propaedeuticArea.nombre,
          this.propaedeuticArea.descripcion,
          this.institutionId
        );
        this.clear();
        await this.getPaginatPropaedeuticArea(1);
      }
      this.clear();
    },
    clear() {
      this.propaedeuticArea = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.propaedeuticArea = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.propaedeuticArea = { ...item };
      // console.log("Item a editar:", this.evaluationTypes);
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deletePropaedeuticArea(itemId);
      await this.getPaginatPropaedeuticArea(1);
      this.clearInline();
    },
  },
};
