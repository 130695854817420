import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  createMotivoMovimientoInventario,
  updateMotivoMovimientoInventario,
  deleteMotivoMovimientoInventario,
  fetchMotivoMovimientoInventario,
} from '../../helpers/knInventoryOptions';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      movReason: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
      },
      movementReasons: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedMotivoMovimientoInventario(1);
    this.movReason.id_autor = this.userData.id;
    this.movReason.id_institucion_educativa = this.institutionId;
    //this.movementReasons = await fetchMotivoMovimientoInventarioAll(); //this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedMotivoMovimientoInventario(1);
        this.movReason.id_autor = this.userData.id;
        this.movReason.id_institucion_educativa = this.institutionId;
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.movReason);
    },
  },

  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedMotivoMovimientoInventario(page = 1) {
      try {
        this.movementReasons = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } =
            await fetchMotivoMovimientoInventario({
              institutionId: this.institutionId,
              systemStatus: !this.showInactive,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });

          if (ok) {
            this.setPaginationCount(count);
            for (const movementReason of data) {
              this.movementReasons.push({ ...movementReason });
            }
          } else {
            console.error(
              'No se pudieron obtener los estatus de los egresos',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los estatus de los egresos:', error);
        this.loading = false;
      }
    },
    async save() {
      await createMotivoMovimientoInventario(
        this.movReason.dato,
        this.movReason.id_autor
      );
      await this.getPaginatedMotivoMovimientoInventario(1);

      //this.movementReasons = await fetchMotivoMovimientoInventarioAll(); //this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
      this.clear();
    },
    clear() {
      this.movReason = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.movReason = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.movReason = { ...item };
      await updateMotivoMovimientoInventario(
        this.movReason.id,
        this.movReason.dato,
        this.movReason.id_autor
      );
      await this.getPaginatedMotivoMovimientoInventario(1);
      //this.movementReasons = await fetchMotivoMovimientoInventarioAll(); //this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteMotivoMovimientoInventario(itemId);
      await this.getPaginatedMotivoMovimientoInventario(1);
      //this.movementReasons = await fetchMotivoMovimientoInventarioAll(); //this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
      this.clearInline();
    },
  },
};
