import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchEducationalLevel,
  createEducationalLevel,
  updateEducationalLevel,
  deleteEducationalLevel,
} from '../../helpers/KnGroupsOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      educationalLevel: {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        id_institucion_educativa: null,
      },
      rvoe: null,
      educationalLevels: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.educationalLevel);
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginateEducationalLevel(1);
    this.educationalLevel.id_institucion_educativa = this.institutionId;
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginateEducationalLevel(1);
        this.EducationalLevel.id_institucion_educativa =
          this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginateEducationalLevel(page = 1) {
      try {
        this.educationalLevels = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } =
            await fetchEducationalLevel({
              institutionId: this.institutionId,
              systemStatus: !this.showInactive,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });

          if (ok) {
            this.setPaginationCount(count);
            for (const educationalLevels of data) {
              this.educationalLevels.push({
                ...educationalLevels,
              });
            }
          } else {
            console.error(
              'No se pudieron obtener los niveles escolares',
              message
            );
          }
          this.loading = false;
        }
      } catch (error) {
        console.error(
          'Error al obtener los niveles escolares:',
          error
        );
        this.loading = false;
      }
    },

    async save() {
      if (this.toUpdate) {
        await updateEducationalLevel(
          this.educationalLevel.id,
          this.educationalLevel.nombre,
          this.educationalLevel.descripcion,
          this.rvoe,
          this.institutionId
        );
        this.clear();
        await this.getPaginateEducationalLevel(1);
        this.clearInline();
        this.toUpdate = false;
      } else {
        await createEducationalLevel(
          this.educationalLevel.nombre,
          this.educationalLevel.descripcion,
          this.rvoe,
          this.institutionId
        );
        this.clear();
        this.clearInline();
        await this.getPaginateEducationalLevel(1);
      }
      this.clear();
    },
    clear() {
      this.educationalLevel = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        id_institucion_educativa: this.institutionId,
      };
      this.rvoe = null,
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.educationalLevel = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        id_institucion_educativa: this.institutionId,
      };
      this.rvoe = null,
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.educationalLevel = { ...item };
      // console.log("Item a editar:", this.evaluationTypes);
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteEducationalLevel(itemId);
      await this.getPaginateEducationalLevel(1);
      this.clearInline();
    },
  },
};
