import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchPenaltiys,
  updatePenalty,
  createPenalty,
  deletePenalty,
} from '../../helpers/KnFinancialOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnPagination from '../../../shared/components/KnPagination.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      penalty: {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        monto: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      penalties: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedPenaltiys(1);
    this.penalty.id_autor = this.userData.id;
    this.penalty.id_institucion_educativa = this.institutionId;
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.penalty);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedPenaltiys(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedPenaltiys(1);
        this.penalty.id_autor = this.userData.id;
        this.penalty.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedPenaltiys(page = 1) {
      try {
        this.penalties = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchPenaltiys({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const penalty of data) {
              this.penalties.push({ ...penalty });
            }
          } else {
            console.error(
              'No se pudieron obtener los tipos de empleados',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los tipos de empleados:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updatePenalty(
          this.penalty.id,
          this.penalty.nombre,
          this.penalty.descripcion,
          this.penalty.monto,
          this.penalty.id_autor,
          this.penalty.id_institucion_educativa
        );
        await this.getPaginatedPenaltiys(1);
        this.toUpdate = false;
      } else {
        await createPenalty(
          this.penalty.nombre,
          this.penalty.descripcion,
          this.penalty.monto,
          this.penalty.id_autor,
          this.penalty.id_institucion_educativa
        );
        this.clear();
        await this.getPaginatedPenaltiys(1);
      }
      this.clear();
    },
    clear() {
      this.penalty = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        monto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.penalty = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        monto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.penalty = { ...item };
      // console.log("Item a editar:", this.penalty);
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deletePenalty(itemId);
      await this.getPaginatedPenaltiys(1);
      this.clearInline();
    },
  },
};
