import { mapState, mapMutations } from "vuex";
import { addressUtilsMixin } from "../../../shared/mixins/addressUtilsMixin";
import { validationFormMixin } from "../../../shared/mixins/validationFormMixin";
import {
  updateAddress,
  updateBranch,
  createAddress,
  createBranch,
  fetchGroupSucursals,
  deleteAddress,
  deleteBranch,
} from "../../helpers/KnGroupsOptions";
import KnFormSubtitle from "../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue";
import KnFormActionButtons from "../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue";
import KnAddress from "../../../shared/components/KnAddress.vue";
import KnPagination from "../../../shared/components/KnPagination.vue";
import { paginationMixin } from "@/modules/shared/mixins/paginationMixin";

import {
  getItem
} from "@/api/api-methods";
export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnAddress,
    KnPagination
  },
  mixins: [paginationMixin,addressUtilsMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      branch: {
        id: "NA", // No asignado
        numero_sucursal: null,
        nombre_sucursal: null,
        autor: null,
        institucion_educativa: null,
      },
      branchAddress: {
        id: "NA", // No asignado
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: "",
        numero_contacto: "7841256898",
        autor: null,
        institucion_educativa: null,
      },
      groups: [],
      classrooms: [],
      branches: [],
      schoolCycle: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["institutionId", "userData"]),
    actionTitle() {
      return this.toUpdate ? "Actualizar" : "Crear";
    },
    invalidFields() {
      return (
        this.objectHasNulls(this.branch) ||
        this.objectHasNulls(this.branchAddress)
      );
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedGroupSucursals(1);
    this.branch.autor = this.userData.id;
    this.branch.institucion_educativa = this.institutionId;
    this.branchAddress.autor = this.userData.id;
    this.branchAddress.institucion_educativa = this.institutionId;
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedGroupSucursals(1)
      },
    },
  },
  methods: {
    ...mapMutations(["setIsLogin"]),
    handlePaginationChange() {
      this.loading = true;
  
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    async getPaginatedGroupSucursals(page = 1) {
      try {
        this.branches = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          //this.loading = true;

          const { ok, data, message, count } = await fetchGroupSucursals({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const branch of data) {
              this.branches.push({ ...branch });
            }
          } else {
            console.error(
              "No se pudieron obtener los estatus de los egresos",
              message
            );
          }

          //this.loading = false;
        }
      } catch (error) {
        console.error("Error al obtener los estatus de los egresos:", error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        this.branchAddress.id_institucion_educativa = this.institutionId;
        await updateAddress(this.branchAddress.id, this.branchAddress);
        await updateBranch(
          this.branch.id,
          this.branch.numero_sucursal,
          this.branch.nombre_sucursal,
          this.branchAddress.id,
          this.institutionId
        );
        this.toUpdate = false;
        await this.getPaginatedGroupSucursals(1);

      } else {
        // console.log("Primero vamos a crear la dirección: ", this.branchAddress);
        this.branchAddress.id_institucion_educativa = this.institutionId;
        const addressId = await createAddress(this.branchAddress);
        this.branch.direccion_sucursal = addressId;
        // console.log("Siguiente vamos a crear la sucursal: ", addressId.id);
        await createBranch(
          this.branch.numero_sucursal,
          this.branch.nombre_sucursal,
          addressId.id,
          this.institutionId
        );
        this.clear();
        await this.getPaginatedGroupSucursals(1);
      }
      this.clear();
    },
    clear() {
      this.branch = {
        id: "NA", // No asignado
        numero_sucursal: null,
        nombre_sucursal: null,
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.branchAddress = {
        id: "NA", // No asignado
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: "",
        numero_contacto: "",
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.branch = {
        id: "NA", // No asignado
        numero_sucursal: null,
        nombre_sucursal: null,
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.branchAddress = {
        id: "NA", // No asignado
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: "",
        numero_contacto: "",
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async edit(item) {
      this.branch = item;
      // console.log('Branch', this.branch)
      const groupSucursalsAddress = await getItem(
        `/app-personas/direccion/${this.branch.direccion_sucursal.id}`
      );
      this.branchAddress = { ...groupSucursalsAddress };
      this.branchAddress.codigo_postal = groupSucursalsAddress.codigo_postal.id;
      this.branchAddress.code = groupSucursalsAddress.codigo_postal.codigo_postal;
      this.branchAddress.municipio =
      groupSucursalsAddress.codigo_postal.nombre_municipio;
      this.branchAddress.institucion_educativa = item.institucion_educativa.id
      /*const zip = await fetchZipById(item.direccion_sucursal.codigo_postal);
      console.log('Zip', zip)
      this.branchAddress = item.direccion_sucursal;
      this.branchAddress.codigo_postal = zip.results;
      console.log("Item a editar:", item);*/
      this.toUpdate = true;
    },
    async deleteInline(item) {
      await deleteAddress(item.direccion_sucursal.id);
      await deleteBranch(item.id);
      await this.getPaginatedGroupSucursals(1)
      //this.groups = await fetchBranchesByEI(this.institutionId);
      this.clearInline();
    },
    fullAddress({
      calle: ca,
      numero_exterior: ne,
      numero_interior: ni,
      colonia: col,
      ciudad: ci,
      estado: es,
    }) {
      const fullAddress = `${ca} ${ne}${
        ni ? " " + ni : ""
      }, Col. ${col}, ${ci}, ${es}.`;
      return fullAddress;
    },
  },
};
