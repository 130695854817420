<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvtipoempleado')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Tipos de empleado</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-employee-types />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnEmployeeTypes from '../components/KnEmployeeTypes/KnEmployeeTypes.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    KnEmployeeTypes,
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
