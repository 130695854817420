import { mapState, mapMutations } from 'vuex';
import {
  fetchTuitions,
  fetchCoinsByIE,
  updateTuition,
  createTuition,
  deleteTuition,
} from '../../helpers/KnFinancialOptions';
import { dateUtilsMixin } from '../../../shared/mixins/dateUtilsMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnPagination from '../../../shared/components/KnPagination.vue';

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, dateUtilsMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      tuition: {
        id: 'NA', // No asignado
        nombre_colegiatura: null,
        descripcion: null,
        monto: null,
        id_moneda: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      tuitions: [],
      coins: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedTuitions(1);
    this.tuition.id_autor = this.userData.id;
    this.tuition.id_institucion_educativa = this.institutionId;
    //this.tuitions = await fetchTuitionsByEI(this.institutionId);
    this.coins = await fetchCoinsByIE(this.institutionId);
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    activeCoins() {
      if (this.coins && this.coins.results) {
        return this.coins.results.filter(
          (coin) => coin.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    invalidFields() {
      return this.objectHasNulls(this.tuition);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedTuitions(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedTuitions(1);
        this.tuition.id_autor = this.userData.id;
        this.tuition.id_institucion_educativa = this.institutionId;
        //this.tuitions = await fetchTuitionsByEI(this.institutionId);
        this.coins = await fetchCoinsByIE(this.institutionId);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedTuitions(page = 1) {
      try {
        this.tuitions = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchTuitions({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const tuition of data) {
              this.tuitions.push({ ...tuition });
            }
          } else {
            console.error('No se pudieron obtener las colegiaturas', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las colegiaturas:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateTuition(
          this.tuition.id,
          this.tuition.nombre_colegiatura,
          this.tuition.descripcion,
          this.tuition.monto,
          this.tuition.id_moneda,
          this.tuition.id_institucion_educativa
        );
        this.toUpdate = false;
        await this.getPaginatedTuitions(1);
      } else {
        await createTuition(
          this.tuition.nombre_colegiatura,
          this.tuition.descripcion,
          this.tuition.monto,
          this.tuition.id_moneda,
          this.tuition.id_institucion_educativa
        );
        this.clear();
        await this.getPaginatedTuitions(1);
      }
      this.clear();
    },
    clear() {
      this.tuition = {
        id: 'NA', // No asignado
        nombre_colegiatura: null,
        descripcion: null,
        monto: null,
        id_moneda: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.tuition = {
        id: 'NA', // No asignado
        nombre_colegiatura: null,
        descripcion: null,
        monto: null,
        id_moneda: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.tuition = { ...item };
      this.tuition.id_moneda = item.moneda.id;
      this.tuition.id_institucion_educativa = item.institucion_educativa.id;
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteTuition(itemId);
      await this.getPaginatedTuitions(1);
      this.clearInline();
    },
  },
};
