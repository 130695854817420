import { mapState } from 'vuex';

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';

import { getItem } from '../../../../api/api-methods';
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin';
import { fetchCreateCSD } from '../../helpers/CSDUtils';
import { generalRepoMixin } from '../../../shared/mixins/generalRepoMixin';
export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnLocalAlert,
  },
  mixins: [fileUtilsMixin, generalRepoMixin],
  data() {
    return {
      selectedInstitution: null,
      password: '',
      showPassword: false,
      institutions: [],
      privateKey: {},
      certificate: {},
      invalidFields: false,
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      errors: [],
    };
  },
  async created() {
    const institutionsRes = await getItem(
      '/app-personas/filters/institucion-educativa?estatus_sistema=true'
    );
    this.institutions = institutionsRes.results;
  },
  watch: {
    institutionId: {
      async handler() {
        const institutionsRes = await getItem(
          '/app-personas/filters/institucion-educativa?estatus_sistema=true'
        );
        this.institutions = institutionsRes.results;
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
  },

  methods: {
    submitForm() {
      // console.log("Formulario enviado");
      this.save();
    },
    async save() {
      try {
        this.loading = true;
        this.alertText = 'Por favor, espere. Cargando...';
        this.showAlert = true;
        this.alertType = 'info';
        const [resPrivateKey, resCertificate] = await Promise.all([
          this.uploadFile(this.privateKey),
          this.uploadFile(this.certificate),
        ]);

        const CSDResult = await fetchCreateCSD({
          id_institucion_educativa: this.institutionId,
          certificate_path: resCertificate.archivos[0].url_archivo,
          private_key_path: resPrivateKey.archivos[0].url_archivo,
          private_key_password: this.password,
        });
        // console.log({ CSDResult });
        if (CSDResult.e) this.errors.push(CSDResult.e);

        if (this.errors.length) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType === 'success';
          this.alertColor = 'success';
          this.alertText = 'CSD creado exitosamente';
          this.clear();
        }
      } catch (error) {
        this.handleSaveError(error);
      } finally {
        this.loading = false;
      }
    },

    handleSaveError(error) {
      if (
        error.details &&
        error.details.Rfc &&
        error.details.Rfc.includes('Ya existe un CSD asociado a este RFC')
      ) {
        this.errors.push('Ya existe un CSD asociado a este RFC');
        // console.log("Ya existe un CSD asociado a este RFC");
      } else {
        this.errors.push('Ocurrió un error al guardar:');
        // console.log("Ocurrió un error al guardar:", error);
      }
    },
    clear() {
      // console.log('clear');
      this.selectedInstitution = null;
      this.password = '';
      this.showPassword = false;
      this.privateKey = {};
      this.certificate = {};
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        // console.log('Bien');
        this.showAlert = false;
        this.clear();
      } else {
        this.closeAlert();
      }
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
  },
};
