import { mapState, mapMutations } from 'vuex';
import {
  fetchPromptPaymentDiscounts,
  updatePromptPaymentDiscount,
  createPromptPaymentDiscount,
  deletePromptPaymentDiscount,
} from '../../helpers/KnFinancialOptions';
import { dateUtilsMixin } from '../../../shared/mixins/dateUtilsMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnPagination from '../../../shared/components/KnPagination.vue';

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnPagination,
  },
  mixins: [paginationMixin, dateUtilsMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      promptPaymentDiscount: {
        id: 'NA', // No asignado
        nombre_descuento: null,
        porcentaje_descuento: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      promptPaymentDiscounts: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedPromptPaymentDiscounts(1);
    this.promptPaymentDiscount.id_autor = this.userData.id;
    this.promptPaymentDiscount.id_institucion_educativa = this.institutionId;
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedPromptPaymentDiscounts(1);
        this.promptPaymentDiscount.id_autor = this.userData.id;
        this.promptPaymentDiscount.id_institucion_educativa =
          this.institutionId;
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.promptPaymentDiscount);
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedPromptPaymentDiscounts(page = 1) {
      try {
        this.promptPaymentDiscounts = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } =
            await fetchPromptPaymentDiscounts({
              institutionId: this.institutionId,
              systemStatus: !this.showInactive,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });

          if (ok) {
            this.setPaginationCount(count);
            for (const promptPaymentDiscount of data) {
              this.promptPaymentDiscounts.push({ ...promptPaymentDiscount });
            }
          } else {
            console.error(
              'No se pudieron obtener los tipos de empleados',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los tipos de empleados:', error);
        this.loading = false;
      }
    },
    async save() {
      var resultPaymentDiscount =
        this.promptPaymentDiscount.porcentaje_descuento / 100;
      if (this.toUpdate) {
        await updatePromptPaymentDiscount(
          this.promptPaymentDiscount.id,
          this.promptPaymentDiscount.nombre_descuento,
          resultPaymentDiscount,
          this.promptPaymentDiscount.fecha_inicio,
          this.promptPaymentDiscount.fecha_fin,
          this.promptPaymentDiscount.id_institucion_educativa,
          this.promptPaymentDiscount.id_autor
        );
        await this.getPaginatedPromptPaymentDiscounts(1);
        this.toUpdate = false;
      } else {
        await createPromptPaymentDiscount(
          this.promptPaymentDiscount.nombre_descuento,
          resultPaymentDiscount,
          this.promptPaymentDiscount.fecha_inicio,
          this.promptPaymentDiscount.fecha_fin,
          this.promptPaymentDiscount.id_institucion_educativa,
          this.promptPaymentDiscount.id_autor
        );
        this.clear();
        await this.getPaginatedPromptPaymentDiscounts(1);
      }
      this.clear();
    },
    clear() {
      this.promptPaymentDiscount = {
        id: 'NA', // No asignado
        nombre_descuento: null,
        porcentaje_descuento: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.promptPaymentDiscount = {
        id: 'NA', // No asignado
        nombre_descuento: null,
        porcentaje_descuento: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.promptPaymentDiscount = { ...item };
      this.promptPaymentDiscount.porcentaje_descuento =
        item.porcentaje_descuento * 100;
      this.promptPaymentDiscount.fecha_inicio = this.getDateWithTimeZeroFullStr(
        item.fecha_inicio
      );
      this.promptPaymentDiscount.fecha_fin = this.getDateWithTimeZeroFullStr(
        item.fecha_fin
      );
      this.promptPaymentDiscount.id_institucion_educativa =
        item.institucion_educativa.id;
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deletePromptPaymentDiscount(itemId);
      await this.getPaginatedPromptPaymentDiscounts(1);

      this.clearInline();
    },
  },
};
