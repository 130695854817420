import { mapState, mapMutations } from "vuex";
import { validationFormMixin } from "../../../shared/mixins/validationFormMixin";

import {
  deleteExpenseStatus,
  createExpenseStatus,
  updateExpenseStatus,
  fetchExpenseStatus,
} from "../../helpers/knExpenseOption";
import { paginationMixin } from "@/modules/shared/mixins/paginationMixin";
import KnFormSubtitle from "../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue";
import KnFormActionButtons from "../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue";
import KnListWithActions from "../../../shared/components/KnListWithActions/KnListWithActions.vue";

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      expenseStatus: {
        id: "NA", // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      status: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedExpenseStatus(1);
    this.expenseStatus.id_autor = this.userData.id;
    this.expenseStatus.id_institucion_educativa = this.institutionId;
  },
  computed: {
    ...mapState(["institutionId", "userData"]),
    actionTitle() {
      return this.toUpdate ? "Actualizar" : "Agregar";
    },
    invalidFields() {
      return this.objectHasNulls(this.expenseStatus);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedExpenseStatus(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedExpenseStatus(1);
      },
    },
  },
  methods: {
    ...mapMutations(["setIsLogin"]),
    async getPaginatedExpenseStatus(page = 1) {
      try {
        this.status = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchExpenseStatus({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const expense of data) {
              this.status.push({ ...expense });
            }
          } else {
            console.error(
              "No se pudieron obtener los estatus de los egresos",
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error("Error al obtener los estatus de los egresos:", error);
        this.loading = false;
      }
    },

    async save() {
      await createExpenseStatus(
        this.expenseStatus.dato,
        this.expenseStatus.id_autor,
        this.expenseStatus.id_institucion_educativa
      );
      await this.getPaginatedExpenseStatus(1);
      this.clear();
    },
    clear() {
      this.expenseStatus = {
        id: "NA", // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.expenseStatus = {
        id: "NA", // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.expenseStatus = { ...item };
      await updateExpenseStatus(
        this.expenseStatus.id,
        this.expenseStatus.dato,
        this.expenseStatus.id_autor,
        this.expenseStatus.id_institucion_educativa
      );
      await this.getPaginatedExpenseStatus(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteExpenseStatus(itemId);
      await this.getPaginatedExpenseStatus(1);
      this.clearInline();
    },
  },
};
