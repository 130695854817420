import { mapState, mapMutations } from 'vuex';
import {
  fetchSuplierCategory,
  fetchSuplierSubCategory,
  updateSupplierSubcategory,
  createSupplierSubcategory,
  deleteSupplierSubcategory,
} from '../../helpers/knSupplierOptions.js';
import { validationFormMixin } from '../../../../modules/shared/mixins/validationFormMixin';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      subcategory: {
        id: 'NA', // No asignado
        dato: null,
        categoria: null,
        id_autor: null,
        institucion_educativa: null,
      },
      subcategories: [],
      categories: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedSuplierCategory(1);
    await this.getPaginatedSuplierSubCategorys(1);
    this.subcategory.id_autor = this.userData.id;
    this.subcategory.institucion_educativa = this.institutionId;
    //this.subcategories = await fetchSupplierSubcategoriesByEI(this.institutionId)
    //this.categories = await fetchSupplierCategoriesByEI(this.institutionId)
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedSuplierCategory(1);
        await this.getPaginatedSuplierSubCategorys(1);
        this.subcategory.id_autor = this.userData.id;
        this.subcategory.institucion_educativa = this.institutionId;
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.subcategory);
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedSuplierCategory(page = 1) {
      try {
        this.categories = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchSuplierCategory({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const category of data) {
              this.categories.push({ ...category });
            }
          } else {
            console.error(
              'No se pudieron obtener las categorias de los proveedores',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error(
          'Error al obtener las categorias de los proveedores:',
          error
        );
        this.loading = false;
      }
    },
    async getPaginatedSuplierSubCategorys(page = 1) {
      try {
        this.subcategories = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchSuplierSubCategory({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const subcategory of data) {
              this.subcategories.push({ ...subcategory });
            }
          } else {
            console.error(
              'No se pudieron obtener las subcategorias de los proveedores',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error(
          'Error al obtener las subcategorias de los proveedores:',
          error
        );
        this.loading = false;
      }
    },
    async save() {
      await createSupplierSubcategory(
        this.subcategory.dato,
        this.subcategory.categoria,
        this.subcategory.institucion_educativa
      );

      await this.getPaginatedSuplierSubCategorys(1);
      //this.subcategories = await fetchSupplierSubcategoriesByEI(this.institutionId)
      this.clear();
    },
    clear() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        categoria: null,
        id_autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        categoria: null,
        id_autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.subcategory = { ...item };
      this.subcategory.categoria = item.categoria.id;
      this.toUpdate = true;
      await updateSupplierSubcategory(
        this.subcategory.id,
        this.subcategory.dato,
        this.subcategory.categoria,
        this.institutionId
      );
      await this.getPaginatedSuplierSubCategorys(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteSupplierSubcategory(itemId);
      await this.getPaginatedSuplierSubCategorys(1);
      //this.subcategories = await fetchSupplierSubcategoriesByEI(this.institutionId)
      this.clearInline();
    },
  },
};
