import { mapState, mapMutations } from 'vuex';
import {
  fetchExpenseSubCategory,
  updateExpenseSubCategory,
  createExpenseSubCategory,
  deleteExpenseSubCategory,
} from '../../helpers/knExpenseOption';

import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      expenseSubCategory: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      expenseCategories: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedExpenseSubCategory(1);
    this.expenseSubCategory.id_autor = this.userData.id;
    this.expenseSubCategory.id_institucion_educativa = this.institutionId;
    //this.expenseCategories = await fetchExpenseSubCategoryByEI(this.institutionId)
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedExpenseSubCategory(1);
        this.expenseSubCategory.id_autor = this.userData.id;
        this.expenseSubCategory.id_institucion_educativa = this.institutionId;
        //this.expenseCategories = await fetchExpenseSubCategoryByEI(this.institutionId)
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.expenseSubCategory);
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedExpenseSubCategory(page = 1) {
      try {
        this.expenseCategories = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchExpenseSubCategory({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const expense of data) {
              this.expenseCategories.push({ ...expense });
            }
          } else {
            console.error(
              'No se pudieron obtener los egresos por subcategorias',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los egresos por subcategorias:', error);
        this.loading = false;
      }
    },
    async save() {
      await createExpenseSubCategory(
        this.expenseSubCategory.dato,
        this.expenseSubCategory.id_autor,
        this.expenseSubCategory.id_institucion_educativa
      );
      await this.getPaginatedExpenseSubCategory(1);
      //this.expenseCategories = await fetchExpenseSubCategoryByEI(this.institutionId)
      this.clear();
    },
    clear() {
      this.expenseSubCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.expenseSubCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    /*edit(item) {
      this.expenseSubCategory = { ...item }
      console.log('Item a editar:', this.expenseSubCategory);
      this.toUpdate = true
    },*/
    async updateInline(item) {
      this.expenseSubCategory = { ...item };
      await updateExpenseSubCategory(
        this.expenseSubCategory.id,
        this.expenseSubCategory.dato,
        this.expenseSubCategory.id_autor,
        this.expenseSubCategory.id_institucion_educativa
      );
      await this.getPaginatedExpenseSubCategory(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteExpenseSubCategory(itemId);
      await this.getPaginatedExpenseSubCategory(1);
      //this.expenseCategories = await fetchExpenseSubCategoryByEI(this.institutionId)
      this.clearInline();
    },
  },
};
