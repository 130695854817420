import { mapMutations, mapState } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchIncomeStatus,
  createIncomeStatus,
  updateIncomeStatus,
  deleteIncomeStatus,
} from '../../helpers/KnIncomeOptions';

import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      incomeStatus: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      status: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedIncomeStatus(1);
    this.incomeStatus.id_autor = this.userData.id;
    this.incomeStatus.id_institucion_educativa = this.institutionId;
    //this.status = await fetchIncomeStatusByEI(this.institutionId);
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedIncomeStatus(1);
        this.incomeStatus.id_autor = this.userData.id;
        this.incomeStatus.id_institucion_educativa = this.institutionId;
        //this.status = await fetchIncomeStatusByEI(this.institutionId);
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar';
    },
    activeIncomeStatus() {
      if (this.status && this.status.results) {
        return this.status.results.filter(
          (category) => category.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    invalidFields() {
      return this.objectHasNulls(this.incomeStatus);
    },
  },

  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedIncomeStatus(page = 1) {
      try {
        this.status = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchIncomeStatus({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const income of data) {
              this.status.push({ ...income });
            }
          } else {
            console.error(
              'No se pudieron obtener los estatus de los egresos',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los estatus de los egresos:', error);
        this.loading = false;
      }
    },
    async save() {
      await createIncomeStatus(
        this.incomeStatus.dato,
        this.incomeStatus.id_autor,
        this.incomeStatus.id_institucion_educativa
      );
      await this.getPaginatedIncomeStatus(1);
      //this.status = await fetchIncomeStatusByEI(this.institutionId);
      this.clear();
    },
    clear() {
      this.incomeStatus = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.incomeStatus = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      //console.log('updateInline item', item);
      this.incomeStatus = { ...item };
      await updateIncomeStatus(
        this.incomeStatus.id,
        this.incomeStatus.dato,
        this.incomeStatus.id_autor,
        this.incomeStatus.id_institucion_educativa
      );
      await this.getPaginatedIncomeStatus(1);
      //this.status = await fetchIncomeStatusByEI(this.institutionId);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteIncomeStatus(itemId);
      await this.getPaginatedIncomeStatus(1);
      //this.status = await fetchIncomeStatusByEI(this.institutionId);
      this.clearInline();
    },
  },
};
