<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvbanco')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Bancos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-banks />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('cuenta')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Cuentas</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-bank-account />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvformadepago')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Formas de pago</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-payment-methods />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvpenalizacion')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Penalizaciones</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-penalties />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('descuentoprontopago')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Descuentos pronto pago</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-prompt-payment-discounts />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('colegiatura')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Colegiaturas</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-tuitions />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('factura')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">CSD</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnFinancialCSD />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnFinancialVarBanks from '../components/KnFinancialVarBanks/KnFinancialVarBanks.vue';
import KnFinancialVarBankAccount from '../components/KnFinancialVarBankAccount/KnFinancialVarBankAccount.vue';
import KnFinancialVarPaymentMethods from '../components/KnFinancialVarPaymentMethods/KnFinancialVarPaymentMethods.vue';
import KnFinancialVarPromptPaymentDiscounts from '../components/KnFinancialVarPromptPaymentDiscounts/KnFinancialVarPromptPaymentDiscounts.vue';
import KnFinancialVarTuitions from '../components/KnFinancialVarTuitions/KnFinancialVarTuitions.vue';
import KnFinancialVarPenalties from '../components/KnFinancialVarPenalties/KnFinancialVarPenalties.vue';
import KnFinancialCSD from '../components/KnFinancialCSD/KnFinancialCSD.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    KnFinancialVarBanks,
    KnFinancialVarBankAccount,
    KnFinancialVarPaymentMethods,
    KnFinancialVarPromptPaymentDiscounts,
    KnFinancialVarTuitions,
    KnFinancialVarPenalties,
    KnFinancialCSD,
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
