import KnGroupClassroom from '../../components/KnGroupClassroom/KnGroupClassroom.vue';
import KnGroupSucursals from '../../components/KnGroupSucursals/KnGroupSucursals.vue';
import KnInterfaceColorTheme from '../../components/KnInterfaceColorTheme/KnInterfaceColorTheme.vue';
import { canView } from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'InterfaceSetting',
  components: { KnGroupClassroom, KnGroupSucursals, KnInterfaceColorTheme },
  data() {
    return {};
  },
  computed: {},
  methods: {
    canView: canView,
  },
};
