import { api } from '@/api/axios-base';
import { getItem } from '@/api/api-methods';

export const fetchMvInventarioAll = async () => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-inventarios/filters/mv-movimiento-inventario?estatus_sistema=true`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error('Error al intentar obtener mv. movimiento inventario', error);
  }
};
export const fetchMotivoMovimientoInventarioAll = async () => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-inventarios/filters/motivo-movimiento-inventario?estatus_sistema=true`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener el motivo movimiento inventario',
      error
    );
  }
};
export const fetchMovimientoInventarioByEI = async (institutionId) => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-inventarios/filters/movimiento-inventario?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener movimiento inventario por institucion educativa',
      error
    );
  }
};
export const fetchMovimientoInventario = async ({
  institutionId = null,
  producto = null,
  tipo_movimiento = null,
  motivo_movimiento = null,
  almacen = null,
  moneda = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionIdFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const productFilter = producto ? `&producto=${producto}` : '';
    const typeMoveFilter = tipo_movimiento
      ? `&tipo_movimiento=${tipo_movimiento}`
      : '';
    const mvMoveFilter = motivo_movimiento
      ? `&motivo_movimiento=${motivo_movimiento}`
      : '';
    const wharehouseFilter = almacen ? `&almacen=${almacen}` : '';
    const coinFilter = moneda ? `&moneda=${moneda}` : '';

    const autorFilter = autor !== null ? `&autor=${autor}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-inventarios/filters/movimiento-inventario?'.concat(
        institutionIdFilter,
        productFilter,
        typeMoveFilter,
        mvMoveFilter,
        wharehouseFilter,
        coinFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
export const fetchTipoMovimientoInventario = async ({
  dato = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const datoFilter = dato ? `&dato=${dato}` : '';
    const autorFilter = autor !== null ? `&autor=${autor}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-inventarios/filters/mv-movimiento-inventario?'.concat(
        datoFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
export const fetchMotivoMovimientoInventario = async ({
  dato = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const datoFilter = dato ? `&dato=${dato}` : '';
    const autorFilter = autor !== null ? `&autor=${autor}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-inventarios/filters/motivo-movimiento-inventario?'.concat(
        datoFilter,
        autorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los egresos por categoria. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los egresos por categoria. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los egresos por categoria. ' + error,
      count: 0,
    };
  }
};
export const createOption = async (movTypeDato, movTypeIdautor) => {
  try {
    const response = await api.post(
      '/app-inventarios/tipo-movimiento-inventario',
      {
        dato: movTypeDato, //this.movType.dato,
        autor: movTypeIdautor, //this.movType.id_autor
      }
    );
    if (response.error) {
      this.errors.push(response.error);
    }
  } catch (error) {
    console.error(
      'Error al intentar crear el tipo de movimiento de inventario',
      error
    );
    this.errors.push(
      'Error al intentar crear el tipo de movimiento de inventario'
    );
  }
};
export const updateOption = async (
  idMvInventario,
  movTypeDato,
  movTypeIdautor
) => {
  try {
    const response = await api.put(
      `/app-inventarios/tipo-movimiento-inventario/${idMvInventario}`,
      {
        dato: movTypeDato, //this.movType.dato,
        autor: movTypeIdautor, //this.movType.id_autor
      }
    );
    if (response.error) {
      this.errors.push(response.error);
    }
  } catch (error) {
    console.error(
      'Error al intentar actualizar el tipo de movimiento de inventario',
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteOption = async (movTypeId) => {
  try {
    const response = await api.delete(
      `/app-inventarios/tipo-movimiento-inventario/${movTypeId}`
    );
    if (response.error) {
      this.errors.push(response.error);
    }
  } catch (error) {
    console.error(
      'Error al intentar eliminar el tipo de movimiento de inventario',
      error
    );
  }
};

//Opciones de inventarios
export const fetchCoinsByEI = async (institutionId) => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-administracion/filters/moneda?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchInventoryByEI = async (institutionId) => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-inventarios/filters/inventario?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchWharehouseByEI = async (institutionId) => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-inventarios/filters/almacen?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const fetchProductByEI = async (institutionId) => {
  try {
    //
    //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
    const response = await api.get(
      `/app-productos/filters/product?institucion_educativa=${institutionId}&limit=100`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de proveedor por institucion educativa',
      error
    );
  }
};
export const createInventoryMovement = async (objInventory) => {
  try {
    const response = await api.post('/app-inventarios/movimiento-inventario', {
      ...objInventory,
      /*producto: this.movement.id_producto,
            tipo_movimiento: this.movement.id_tipo_movimiento,
            motivo_movimiento: this.movement.id_motivo_movimiento,
            almacen: this.movement.id_almacen,
            inventario_inicial: this.movement.inventario_inicial,
            cantidad_movimiento: this.movement.cantidad_movimiento,
            inventario_final: this.movement.inventario_final,
            precio_unitario: this.movement.precio_unitario,
            valor_movimiento: this.movement.valor_movimiento,
            moneda: this.movement.id_moneda,
            autor: this.movement.id_autor,
            institucion_educativa: this.movement.id_institucion_educativa,
            autor*/
    });
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear el movimiento de inventario.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear movimiento de inventario', error);
    this.errors.push('Error al intentar crear movimiento de inventario');
  }
};
export const updateInventoryMovement = async (
  idMovimientoInventario,
  objInventory
) => {
  try {
    const response = await api.post(
      `/app-inventarios/movimiento-inventario/${idMovimientoInventario}`,
      {
        ...objInventory,
        /*producto: this.movement.id_producto,
            tipo_movimiento: this.movement.id_tipo_movimiento,
            motivo_movimiento: this.movement.id_motivo_movimiento,
            almacen: this.movement.id_almacen,
            inventario_inicial: this.movement.inventario_inicial,
            cantidad_movimiento: this.movement.cantidad_movimiento,
            inventario_final: this.movement.inventario_final,
            precio_unitario: this.movement.precio_unitario,
            valor_movimiento: this.movement.valor_movimiento,
            moneda: this.movement.id_moneda,
            autor: this.movement.id_autor,
            institucion_educativa: this.movement.id_institucion_educativa,
            autor*/
      }
    );
    const responseData = await response.data;
    if (responseData.e) {
      this.errors.push(
        'Ocurrió un error, no se pudo crear el movimiento de inventario.'
      );
    }
  } catch (error) {
    console.error('Error al intentar crear movimiento de inventario', error);
    this.errors.push('Error al intentar crear movimiento de inventario');
  }
};
// Motivo movimiento inventario
export const createMotivoMovimientoInventario = async (
  movReasonDato,
  movReasonIdAutor
) => {
  try {
    const response = await api.post(
      '/app-inventarios/motivo-movimiento-inventario',
      {
        dato: movReasonDato, //this.movReason.dato,
        autor: movReasonIdAutor, //this.movReason.id_autor
      }
    );
    if (response.error) {
      this.errors.push(response.error);
    }
  } catch (error) {
    console.error(
      'Error al intentar crear el motivo de movimiento de inventario',
      error
    );
    this.errors.push(
      'Error al intentar crear el motivo de movimiento de inventario'
    );
  }
};
export const updateMotivoMovimientoInventario = async (
  movReasonId,
  movReasonDato,
  movReasonIdAutor
) => {
  try {
    const response = await api.put(
      `/app-inventarios/motivo-movimiento-inventario/${movReasonId}`,
      {
        dato: movReasonDato, //this.movReason.dato,
        autor: movReasonIdAutor, //this.movReason.id_autor
      }
    );
    if (response.error) {
      this.errors.push(response.error);
    }
  } catch (error) {
    console.error(
      'Error al intentar actualizar el motivo de movimiento de inventario',
      error
    );
  }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteMotivoMovimientoInventario = async (movReasonId) => {
  try {
    const response = await api.delete(
      `/app-inventarios/motivo-movimiento-inventario/${movReasonId}`
    );
    if (response.error) {
      this.errors.push(response.error);
    }
  } catch (error) {
    console.error(
      'Error al intentar eliminar el motivo de movimiento de inventario',
      error
    );
  }
};
