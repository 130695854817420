<template>
  <v-list>
    <v-list-item-group>
      <template v-for="(item, index) in activeItems">
        <v-list-item :key="item.dato">
          <v-list-item-content>
            <v-text-field
              v-if="toUpdate && item.id === singleItem.id"
              class="mb-0 pb-0"
              v-model="singleItem.dato"
              dense
              outlined
            />
            <v-list-item-title v-else v-text="item.dato" />
          </v-list-item-content>

          <v-list-item-action>
            <v-row v-if="toUpdate && item.id === singleItem.id" no-gutters>
              <v-col>
                <v-btn icon color="primary" @click="cancelInline">
                  <v-icon color="primary"> mdi-cancel </v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn icon color="primary" @click="updateInline">
                  <v-icon color="primary"> mdi-content-save-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col>
                <v-btn icon color="primary" @click="editInline(item)">
                  <v-icon color="primary"> mdi-pencil-outline </v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn icon color="primary" @click="deleteInline(item.id)">
                  <v-icon color="primary"> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="index < activeItems.length - 1"
          :key="index"
        ></v-divider>
      </template>
      <v-pagination
        v-if="paginationCount"
        class="float-md-right float-lg-right float-xl-right"
        v-model="page"
        total-visible="10"
        :length="paginationCount"
        @input="emitChangePage()"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    activeItems: {
      type: Array,
      default: () => [],
    },
    paginationCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      singleItem: {},
      toUpdate: false,
      page: 1,
      isLoading: false,
    };
  },
  watch: {
    paginationCount: function () {
      this.page = 1;
    },
  },
  methods: {
    editInline(item) {
      this.isLoading = false;
      this.singleItem = { ...item };
      this.toUpdate = true;
      this.$emit("editInline", this.singleItem);
    },
    deleteInline(itemId) {
      this.$emit("deleteInline", itemId);
    },
    updateInline() {
      this.isLoading = false;
      this.$emit("updateInline", this.singleItem);
      this.cancelInline();
    },
    cancelInline() {
      this.isLoading = false;
      this.$emit("cancelInline");
      this.singleItem = {};
      this.toUpdate = false;
    },
    emitChangePage() {
      this.$emit("onChangePage", this.page);
    },
  },
};
</script>

<style></style>
