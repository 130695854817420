import { mapGetters, mapState } from 'vuex';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { canAdd, canDelete } from '../../../shared/helpers/permissionsUtils';
import { getItem } from '../../../../api/api-methods';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTableSubjects from '../../../employees/components/KnTableSubjects/KnTableSubjects';
import {
  fecthPostSubject,
  fecthPutSubject,
  fetchGetSubject,
  fetchReactivateSubject,
  fetchRemoveSubject,
} from '../../../employees/helpers/subjectsOptions';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import {
  fetchDeleteCriterion,
  fetchPostCriterion,
  fetchPutCriterion,
} from '../../../employees/helpers/criterionOptions';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { fetchSubjects } from '../../helpers/KnGroupsOptions';

export default {
  name: 'SubjectsIndex',
  components: {
    KnLocalAlert,
    KnTableSubjects,
    KnTabs,
    KnFormSubtitle,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnAutoComplete,
    KnCheckBox,
    KnTextArea,
    KnTextField,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  props: {},

  data() {
    return {
      showInactive: false,
      toUpdate: false,
      valid: true,
      selectedCriterios: [],
      initialCriteria: [],
      addedCriteria: [],
      removedCriteria: [],
      modifiedCriteria: [],
      headers: [
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        { text: 'Descripción', value: 'descripcion', class: 'purple--text' },
        {
          text: 'Titular',
          value: 'titular_materia.datos_personales',
          class: 'purple--text',
        },
        { text: '', value: 'acciones' },
      ],
      popularCriterios: [
        {
          id: 1,
          nombre: 'Trabajo en equipo',
          descripcion:
            'Evaluación de la capacidad de colaborar y trabajar en equipo.',
        },
        {
          id: 2,
          nombre: 'Asistencia',
          descripcion:
            'Evaluación basada en la regularidad y puntualidad de la asistencia.',
        },
        {
          id: 3,
          nombre: 'Participación',
          descripcion:
            'Evaluación de la contribución activa en clases y actividades.',
        },
        {
          id: 4,
          nombre: 'Uniforme',
          descripcion:
            'Evaluación del cumplimiento del código de vestimenta escolar.',
        },
        {
          id: 5,
          nombre: 'Tareas',
          descripcion:
            'Evaluación del cumplimiento y calidad de las tareas asignadas.',
        },
      ],
      readonly: false,
      learningField: [],
      curriculum: [],
      preparatoryArea: [],
      courseInstructors: [],
      loadingPage: false,
      form: {},
      tab: false,
      shouldShowElement: false,
      tabs: [{ name: 'Criterios', value: 99 }],
      valueDeterminate: 49,
      items: [],
      subjectsItems: [],
      /** Variables para alerta */
      showAlert: false,
      alertText: '',
      alertType: '',
      alertColor: null,
      loading: false,
      errors: [],
      warnings: [],
      loadingAlert: false,
      evaluationCriteria: false,
      /*********************** */
      currentPage: 1,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    ...mapGetters(['isTeacherEmploye', 'employeeId']),
    //...mapMutations(['setIsLogin']),
    totalPercentage() {
      const criterios = this.form.criterios_evaluacion || [];

      return criterios.length
        ? criterios
            .reduce((acc, criterio) => {
              return acc + parseFloat(criterio.porcentaje || 0);
            }, 0)
            .toFixed(2)
        : '0.00';
    },

    alertPercentage() {
      const total = parseFloat(this.totalPercentage);
      if (total === 100.0) return 'success';
      return total > 100.0 ? 'error' : 'info';
    },
    /*invalidFields() {
      return this.objectHasNulls(this.form);
    },*/
    invalidFields() {
      const { form } = this;
      const hasValidName = form && form.nombre && form.nombre.trim() !== '';
      const allCriteriaValid =
        form &&
        Array.isArray(form.criterios_evaluacion) &&
        form.criterios_evaluacion.every((criterio) => {
          const hasName = criterio.nombre && criterio.nombre.trim() !== '';
          const hasDescription =
            criterio.descripcion && criterio.descripcion.trim() !== '';
          const hasValidPercentage =
            !isNaN(parseFloat(criterio.porcentaje)) &&
            parseFloat(criterio.porcentaje) >= 0;

          return hasName && hasDescription && hasValidPercentage;
        });
      return hasValidName && allCriteriaValid;
    },
  },
  async created() {
    await this.fillDataForm();
    this.setPaginationLimit(30);
    await this.getPaginatedSubjects(1);
    this.defaultFormSubject();
    this.form.institucion_educativa = this.institutionId;
  },
  /*async created() {
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    await this.getPaginatedSubjects(1);
    //this.form.institucion_educativa = this.institutionId;
  },
  /*
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      await this.getPaginatedSubjects(1); 
      const paramId = this.$route.params.id;
      if (paramId) {
        this.subjectId = this.validateId(paramId);
        await this.loadDataSubject();
      } else this.defaultFormSubject();

      await this.fillDataForm();
    } catch (error) {
      console.error('Error in created:', error);
      this.error = 'Error al cargar datos. ';
      this.setErrorState(
        'Error al cargar datos. Por favor, inténtelo de nuevo.'
      );
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }*/
  watch: {
    search: {
      async handler() {
        if (this.loading) return;
        await this.getPaginatedSubjects(1);
      },
    },
    institutionId: {
      async handler() {
        await this.getPaginatedSubjects(1);
      },
    },
  },

  methods: {
    // #Region peticiones get
    async getPaginatedSubjects(page = 1) {
      try {
        if (!this.loading) {
          this.loading = true;
          this.subjectsItems = [];
          this.currentPage = page;
          this.setPaginationPage(page);

          // console.log('Pagination', this.pagination);
          // console.log('currentPage', this.currentPage);

          const { ok, data, message, count } = await fetchSubjects({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            // await this.populateSubjectHolder(data);
            // await this.fillDataForm();
            for (const subject of data) {
              this.subjectsItems.push({ ...subject });
            }
          } else {
            console.error('No se pudieron obtener las materias', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los las materias:', error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    /*async getSubjects(visible = true) {
      this.loading = true;
      try {
        const limitFilter =
          this.pagination.limit !== null
            ? `&limit=${this.pagination.limit}`
            : '';
        const offsetFilter =
          this.pagination.offset !== 0
            ? `&offset=${this.pagination.offset}`
            : '';
        const searchFilter =
          this.search !== null ? `&nombre=${this.search}` : '';
        const employeeFilter =
          this.isTeacherEmploye && this.employeeId
            ? `&titular_materia=${this.employeeId}`
            : '';

        const responseData = await getItem(
          `/app-personas/filters/materia?institucion_educativa=${this.institutionId}&estatus_sistema=${visible}${limitFilter}${offsetFilter}${searchFilter}${employeeFilter}`
        );
        this.setPaginationCount(responseData.count);
        await this.populateSubjectHolder(responseData.results);
        this.items = responseData.results;
      } catch (error) {
        console.error('Error al obtener materias:', error);
      } finally {
        this.loading = false;
      }
    },*/
    async fillDataForm() {
      const [
        learningFieldRes,
        curriculumRes,
        preparatoryAreaRes,
        // courseInstructorsRes,
      ] = await Promise.all([
        getItem(
          `/app-personas/filters/campo-formativo?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        getItem(
          `/app-personas/filters/plan-estudios?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        getItem(
          `/app-personas/filters/area-propedeutica?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        // getItem(
        //   `/app-personas/filters/empleado?estatus_sistema=true&limit=100&institucion_educativa=${this.institutionId}`
        // ),
      ]);

      this.learningField = learningFieldRes.results;
      this.curriculum = curriculumRes.results;
      this.preparatoryArea = preparatoryAreaRes.results;
      // this.courseInstructors = courseInstructorsRes.results;
    },
    async loadDataSubject() {
      try {
        const { data } = await fetchGetSubject(this.subjectId);
        const {
          titular_materia,
          campos_formativos,
          plan_estudios,
          area_propedeutica,
          criterios_evaluacion,
        } = data;

        this.form = {
          ...data,
          titular_materia: titular_materia ? titular_materia.id : null,
          campos_formativos: campos_formativos
            ? campos_formativos.map((e) => e.id)
            : [],
          plan_estudios: plan_estudios ? plan_estudios.id : null,
          area_propedeutica: area_propedeutica ? area_propedeutica.id : null,
        };
        this.initialCriteria = JSON.parse(JSON.stringify(criterios_evaluacion));
      } catch (error) {
        console.error('Error loading subject data:', error);
      }
    },
    async populateSubjectHolder(items) {
      const promises = items.map(async (subjectHolder) => {
        const { titular_materia } = subjectHolder;

        if (
          titular_materia &&
          typeof titular_materia.datos_personales === 'string'
        ) {
          const personalDataId = titular_materia.datos_personales;

          try {
            const personalData = await getItem(
              `app-personas/datos-personales/${personalDataId}`
            );
            subjectHolder.titular_materia.datos_personales =
              this.getFullName(personalData);
          } catch (error) {
            console.error(
              `Error fetching personal data for ID ${personalDataId}:`,
              error
            );
          }
        } else {
          subjectHolder.titular_materia = { datos_personales: 'Desconocido' };
        }
      });
      await Promise.all(promises);
    },
    //#endregion peticiones get
    defaultFormSubject() {
      this.form = {
        nombre: undefined,
        descripcion: undefined,
        extracurricular: false,
        fecha_elaboracion: undefined,
        titular_materia: undefined,
        plan_estudios: undefined,
        area_propedeutica: undefined,
        institucion_educativa: this.institutionId,
        campos_formativos: [],
        criterios_evaluacion: [],
      };
    },

    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },

    setTabValue(val) {
      this.valueDeterminate = val;
    },
    addNewCriterion() {
      const newCriterion = {
        id: null,
        nombre: '',
        descripcion: '',
        porcentaje: undefined,
      };
      this.form.criterios_evaluacion.push(newCriterion);
      this.addedCriteria.push(newCriterion);
    },

    removeCriterion(index) {
      const criterion = this.form.criterios_evaluacion[index];
      const { id } = criterion;
      if (id) {
        this.removedCriteria.push(criterion);
      }
      this.form.criterios_evaluacion.splice(index, 1);
      this.initialCriteria.splice(index, 1);
      this.removeFromList(this.addedCriteria, criterion);
      this.removeFromList(this.modifiedCriteria, criterion, true);
    },

    updateCriterion(index) {
      const criterion = this.form.criterios_evaluacion[index];
      const original = this.initialCriteria.find(
        ({ id }) => id === criterion.id
      );
      if (!original) return;
      const isModified = ['nombre', 'descripcion', 'porcentaje'].some(
        (key) => original[key] !== criterion[key]
      );
      if (isModified) return this.updateList(this.modifiedCriteria, criterion);
      this.removeFromList(this.modifiedCriteria, criterion, true);
    },

    selectPopularCriterion(criterion) {
      const newCriterion = { ...criterion, id: null, porcentaje: undefined };
      this.form.criterios_evaluacion.push(newCriterion);
      this.addedCriteria.push(newCriterion);
    },

    async saveCriteria() {
      try {
        await Promise.all(
          this.removedCriteria.map(({ id }) => this.deleteCriterion(id))
        );
        const addedCriteria = await Promise.all(
          this.addedCriteria.map((criterion) => this.addCriterion(criterion))
        );
        await Promise.all(
          this.modifiedCriteria.map((criterion) => this.putCriterion(criterion))
        );
        this.addedCriteria = [];
        this.removedCriteria = [];
        this.modifiedCriteria = [];

        const remainingCriteriaIds = [
          ...this.initialCriteria
            .filter((criterion) => criterion.id)
            .map((criterion) => criterion.id),
          ...addedCriteria.map((criterion) => criterion.id),
        ];

        return remainingCriteriaIds;
      } catch (error) {
        console.error('Error saving criteria:', error);
        throw error;
      }
    },

    async saveSubject() {
      this.setLoadingState(true, 'Actualizando materia..', 'info');
      try {
        const remainingCriteriaIds = await this.saveCriteria();
        // console.log('Campos formativos', this.form.campos_formativos);
        await this.putSubject({
          ...this.form,
          plan_estudios: this.form.plan_estudios
            ? typeof this.form.plan_estudios === 'object'
              ? this.form.plan_estudios.id
              : this.form.plan_estudios
            : null,
          area_propedeutica: this.form.area_propedeutica
            ? typeof this.form.area_propedeutica === 'object'
              ? this.form.area_propedeutica.id
              : this.form.area_propedeutica
            : null,
          campos_formativos: this.form.campos_formativos.map((x) =>
            typeof x === 'object' ? x.id : x
          ),
          criterios_evaluacion: remainingCriteriaIds,
        });
        this.setSuccessState('Materia actualizada exitosamente');
      } catch (error) {
        console.error('Error saving subject:', error);
        throw error;
      }
    },
    async createSubject() {
      this.setLoadingState(true, 'Creando materia..', 'info');
      try {
        const remainingCriteriaIds = await this.saveCriteria();
        const subject = await this.postSubject({
          ...this.form,
          criterios_evaluacion: remainingCriteriaIds,
        });
        if (subject)
          this.setSuccessState(`Materia ${subject.nombre} creada exitosamente`);
        else this.setErrorState('Error al intentar crear materia');
      } catch (error) {
        console.error('Error creating subject:', error);
        throw error;
      }
    },

    removeFromList(list, item, strict = false) {
      const index = list.findIndex((el) =>
        strict ? el === item : el.id === item.id
      );
      if (index !== -1) {
        list.splice(index, 1);
      }
    },

    updateList(list, item) {
      const index = list.findIndex((el) => el.id === item.id);
      if (index === -1) {
        list.push(item);
      } else {
        list[index] = item;
      }
    },
    async addCriterion(criterion) {
      const { ok, data } = await fetchPostCriterion(criterion);
      if (ok && data) {
        return data;
      } else {
        console.error('Failed to add criterion', data);
        throw new Error('Failed to add criterion');
      }
    },
    async putCriterion(criterion) {
      const { ok, data } = await fetchPutCriterion(criterion.id, criterion);
      if (ok && data) {
        return data;
      } else {
        console.error('Failed to update criterion', data);
        throw new Error('Failed to update criterion');
      }
    },
    async deleteCriterion(id) {
      const { ok } = await fetchDeleteCriterion(id);
      if (ok) {
        return id;
      } else {
        console.error('Failed to delete criterion');
        throw new Error('Failed to delete criterion');
      }
    },

    async putSubject(subject) {
      const { ok, data } = await fecthPutSubject(subject.id, subject);
      console.log(ok, data);
    },
    async postSubject(subject) {
      const { ok, data } = await fecthPostSubject(subject);
      if (ok) return data;
    },

    async save() {
      if (this.toUpdate) {
        await this.saveSubject();
        this.defaultFormSubject();
        await this.getPaginatedSubjects(this.currentPage);
        this.toUpdate = false;
      } else {
        await this.createSubject();
        this.defaultFormSubject();
        await this.getPaginatedSubjects(1);
      }
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    actionAlertBtn1() {
      this.alertType === 'success' || this.alertType === 'info'
        ? this.returnToTable()
        : this.closeAlert();
    },
    continueAdding() {
      // this.cleanForm()
      this.closeAlert();
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    canAdd: canAdd,
    canDelete: canDelete,
    tableTitle() {
      return this.showInactive ? 'Materias inactivas' : 'Materias';
    },
    buttomActionText() {
      return this.showInactive
        ? 'Ver materias activas'
        : 'Ver materias inactivas';
    },

    getFullName(personalData) {
      const {
        primer_nombre,
        segundo_nombre,
        apellido_paterno,
        apellido_materno,
      } = personalData;
      const firstName = primer_nombre || '';
      const secondName = segundo_nombre || '';
      const lastName1 = apellido_paterno || '';
      const lastName2 = apellido_materno || '';
      return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
    },

    async action2(value) {
      try {
        let response;
        this.showInactive
          ? (response = await fetchReactivateSubject(value))
          : (response = await fetchRemoveSubject(value));

        if (response.ok) {
          await this.getPaginatedSubjects(1);
        }
      } catch (error) {
        console.error('Error processing the request:', error);
      }
    },
    async changePageSubjects(page) {
      this.setPaginationPage(page);
      await this.getPaginatedSubjects(page);
    },
    async actionInactive(value) {
      this.resetPagination();
      await this.getPaginatedSubjects(1);
      this.showInactive = value;
    },
    edit(item) {
      this.form = { ...item };
      this.toUpdate = true;
    },
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
  },
};
