import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  updateBackorder,
  createBackorder,
  deleteBackorder,
  fetchBackorder,
  fetchProductsByEI,
} from '../../helpers/KnProductsOption';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnPagination from '../../../shared/components/KnPagination.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnCheckBox,
    KnSelect,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      backorder: {
        id: 'NA', // No asignado
        nombre: null,
        backorder: false,
        tiempo_entrega_dias: null,
        id_marca_producto: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      backorders: [],
      brands: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedBackorder(1);
    this.backorder.id_autor = this.userData.id;
    this.backorder.id_institucion_educativa = this.institutionId;
    this.brands = await fetchProductsByEI(this.institutionId); //this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
    //this.backorders = await fetchBackorderEI(this.institutionId)//this.fetchResultsByEI('productos', 'backorder', this.institutionId)
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    activeBrands() {
      if (this.brands && this.brands.results) {
        return this.brands.results.filter(
          (brand) => brand.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    invalidFields() {
      return this.objectHasNulls(this.backorder);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedBackorder(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedBackorder(1);
        this.backorder.id_autor = this.userData.id;
        this.backorder.id_institucion_educativa = this.institutionId;
        this.brands = await fetchProductsByEI(this.institutionId);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedBackorder(page = 1) {
      try {
        this.backorders = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchBackorder({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const backorder of data) {
              this.backorders.push({ ...backorder });
            }
          } else {
            console.error('No se pudieron obtener los backorders', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los backorders:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateBackorder(
          this.backorder.id,
          this.backorder.nombre,
          this.backorder.backorder,
          this.backorder.tiempo_entrega_dias,
          this.backorder.id_marca_producto,
          this.backorder.id_institucion_educativa,
          this.backorder.id_autor
        );
        this.toUpdate = false;
        await this.getPaginatedBackorder(1);
      } else {
        await createBackorder(
          this.backorder.nombre,
          this.backorder.backorder,
          this.backorder.tiempo_entrega_dias,
          this.backorder.id_marca_producto,
          this.backorder.id_institucion_educativa,
          this.backorder.id_autor
        );
        this.clear();
        await this.getPaginatedBackorder(1);
      }
      this.clear();
    },
    clear() {
      this.backorder = {
        id: 'NA', // No asignado
        nombre: null,
        backorder: false,
        tiempo_entrega_dias: null,
        id_marca_producto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.backorder = {
        id: 'NA', // No asignado
        nombre: null,
        backorder: false,
        tiempo_entrega_dias: null,
        id_marca_producto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.backorder = { ...item };
      this.backorder.id_marca_producto = item.fabricante.id;
      this.backorder.id_institucion_educativa = item.institucion_educativa.id;
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteBackorder(itemId);
      await this.getPaginatedBackorder(1);
      this.clearInline();
    },
  },
};
