import { emptyTheme } from '../../../shared/helpers/themeOptions';

export default {
  name: 'KnInterfaceColorThemeListItem',
  components: {},
  mixins: [],
  props: {
    theme: {
      type: Object,
      default: () => emptyTheme,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    emitEdit() {
      this.$emit('edit', this.theme);
    },
    emitDelete() {
      this.$emit('delete', this.theme.id);
    },
  },
};
