<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvmarcaproducto')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Marcas</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-brands />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvcodigossat')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Códigos SAT</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-sat-codes />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvcategoriaproducto')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorías de productos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvsubcategoriaproducto')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Subcategorías de producto</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-subcategories />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvuom')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Unidades de medida</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-uom />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('backorder')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Backorders</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-backorders />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvtipoopcionproducto')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Tipos de opciones de producto</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-options-type />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('opcionesproducto')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Opciones de producto</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-options />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('impuesto')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Impuestos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-taxes />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
// import KnProductBrands from '../../components/configuration/KnProducts/KnProductBrands/KnProductBrands.vue'
import KnProductBrands from '../components/KnProductBrands/KnProductBrands.vue';
import KnProductCategories from '../components/KnProductCategories/KnProductCategories.vue';
import KnProductSatCodes from '../components/KnProductSatCodes/KnProductSatCodes.vue';
import KnProductSubcategories from '../components/KnProductSubcategories/KnProductSubcategories.vue';
import KnProductUom from '../components/KnProductUOM/KnProductUOM.vue';
import KnProductBackorders from '../components/KnProductBackorders/KnProductBackorders.vue';
import KnProductOptionsType from '../components/KnProductOptionsType/KnProductOptionsType.vue';
import KnProductOptions from '../components/KnProductOptions/KnProductOptions.vue';
import KnProductTaxes from '../components/KnProductTaxes/KnProductTaxes.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    KnProductBrands,
    KnProductSatCodes,
    KnProductCategories,
    KnProductSubcategories,
    KnProductUom,
    KnProductBackorders,
    KnProductOptionsType,
    KnProductOptions,
    KnProductTaxes,
  },
  methods: {
    canView: canView,
  },
};
</script>
