import { emptyTheme } from '../../../shared/helpers/themeOptions';
export default {
  name: 'KnInterfaceColorThemeEditor',
  components: {},
  mixins: [],
  props: {
    theme: {
      type: Object,
      default: () => emptyTheme,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Columna 1',
          align: 'start',
          sortable: false,
          value: 'col1',
        },
        { text: 'Columna 2', value: 'col2' },
      ],
      items: [
        {
          col1: 'Lorem ipsum dolor sit amet',
          col2: 'Lorem ipsum dolor sit amet',
        },
        {
          col1: 'Nunc suscipit bibendum lobortis',
          col2: 'Nunc suscipit bibendum lobortis',
        },
      ],
    };
  },
  computed: {
    currentTheme: {
      get() {
        return this.theme;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {},
  methods: {},
};
