<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvcategoriaegreso')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorias de egresos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-expense-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvsubcategoriaegreso')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Sub categorias de egresos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-expense-subcategories />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvestatusegreso')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Estatus de egreso</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-expense-status />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnExpenseCategories from '../components/KnExpenseCategories/KnExpenseCategories.vue';
import KnExpenseSubcategories from '../components/KnExpenseSubCategories/KnExpenseSubCategories.vue';
import KnExpenseStatus from '../components/KnExpenseStatus/KnExpenseStatus.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';
export default {
  components: {
    KnExpenseCategories,
    KnExpenseSubcategories,
    KnExpenseStatus,
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
