import { mapState, mapMutations } from 'vuex';

import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchTypeOptionProduct,
  createOption,
  updateOption,
  deleteOption,
} from '../../helpers/KnProductsOption';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      option: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      options: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedTypeOptionProduct(1);
    this.option.id_autor = this.userData.id;
    this.option.id_institucion_educativa = this.institutionId;
    //this.options = await fetchOptionProductEI(this.institutionId)//this.fetchResultsByEI('productos', 'tipo-opcion-producto', this.institutionId)
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.option);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedTypeOptionProduct(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedTypeOptionProduct(1);
        this.option.id_autor = this.userData.id;
        this.option.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedTypeOptionProduct(page = 1) {
      try {
        this.options = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchTypeOptionProduct({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const option of data) {
              this.options.push({ ...option });
            }
          } else {
            console.error(
              'No se pudieron obtener las tipo de opciones de productos',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error(
          'Error al obtener las tipo de opciones de productos:',
          error
        );
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateOption(
          this.option.id,
          this.option.dato,
          this.option.id_institucion_educativa,
          this.option.id_autor
        );
        this.toUpdate = false;
      } else {
        await createOption(
          this.option.dato,
          this.option.id_institucion_educativa,
          this.option.id_autor
        );
        this.clear();
      }
      await this.getPaginatedTypeOptionProduct(1);
      this.clear();
    },
    clear() {
      this.option = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.option = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.option = { ...item };
      await updateOption(
        this.option.id,
        this.option.dato,
        this.option.id_institucion_educativa,
        this.option.id_autor
      );
      await this.getPaginatedTypeOptionProduct(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteOption(itemId);
      await this.getPaginatedTypeOptionProduct(1);
      this.clearInline();
    },
  },
};
