<template>
  <div>
    <v-pagination
      v-if="paginationCount"
      class="float-md-right float-lg-right float-xl-right"
      v-model="page"
      total-visible="10"
      :length="paginationCount"
      @input="emitChangePage()"
    />
  </div>
</template>
<script>
export default {
  props: {
    paginationCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  watch: {
    paginationCount: function () {
      this.page = 1;
    },
  },
  methods: {
    emitChangePage() {
      this.$emit('onChangePage', this.page);
    },
  },
};
</script>
