<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('mvmovimientoinventario')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Tipos de movimiento de inventario</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-inventory-movement-types />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvmotivomovimientoinventario')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">
            Motivos de movimiento de inventario
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-inventory-movement-reasons />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('movimientoinventario')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Movimientos de inventario</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-inventory-movements />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnInventoryMovementReasons from '../components/KnInventoryMovementReasons/KnInventoryMovementReasons.vue';
import KnInventoryMovements from '../components/KnInventoryMovements/KnInventoryMovements.vue';
import KnInventoryMovementTypes from '../components/KnInventoryMovementTypes/KnInventoryMovementTypes.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';

export default {
  components: {
    KnInventoryMovementTypes,
    KnInventoryMovementReasons,
    KnInventoryMovements,
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
