import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchUOM,
  createUnit,
  updateUnit,
  deleteUnit,
} from '../../helpers/KnProductsOption';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      unit: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      units: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedUOM(1);
    this.unit.id_autor = this.userData.id;
    this.unit.id_institucion_educativa = this.institutionId;
    //this.units = await fetchUnitByEI(this.institutionId)
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.unit);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedUOM(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedUOM(1);
        this.unit.id_autor = this.userData.id;
        this.unit.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedUOM(page = 1) {
      try {
        this.units = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchUOM({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const unit of data) {
              this.units.push({ ...unit });
            }
          } else {
            console.error(
              'No se pudieron obtener las unidades de medida',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las unidades de medida:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        await updateUnit(
          this.unit.id,
          this.unit.dato,
          this.unit.id_institucion_educativa,
          this.unit.id_autor
        );
        this.toUpdate = false;
        await this.getPaginatedUOM(1);
      } else {
        await createUnit(
          this.unit.dato,
          this.unit.id_institucion_educativa,
          this.unit.id_autor
        );
        this.clear();
        await this.getPaginatedUOM(1);
      }
      this.clear();
    },
    clear() {
      this.unit = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.unit = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.unit = { ...item };
      await updateUnit(
        this.unit.id,
        this.unit.dato,
        this.unit.id_institucion_educativa,
        this.unit.id_autor
      );
      await this.getPaginatedUOM(1);
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteUnit(itemId);
      await this.getPaginatedUOM(1);
      this.clearInline();
    },
  },
};
