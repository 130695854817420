import { mapState, mapMutations } from 'vuex';
import {
  fetchSuplierCategory,
  createSupplierCategory,
  updateSupplierCategory,
  deleteSupplierCategory,
} from '../../helpers/knSupplierOptions.js';
import { validationFormMixin } from '../../../../modules/shared/mixins/validationFormMixin';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnListWithActions from '../../../shared/components/KnListWithActions/KnListWithActions.vue';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnListWithActions,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      supplierCategory: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      supplierCategories: [],
      toUpdate: false,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedSuplierCategory(1);
    this.supplierCategory.id_autor = this.userData.id;
    this.supplierCategory.id_institucion_educativa = this.institutionId;
    /*this.supplierCategories = await fetchSupplierCategoriesByEI(
      this.institutionId
    );*/
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar';
    },

    invalidFields() {
      return this.objectHasNulls(this.supplierCategory);
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedSuplierCategory(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedSuplierCategory(1);
        this.supplierCategory.id_autor = this.userData.id;
        this.supplierCategory.id_institucion_educativa = this.institutionId;
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedSuplierCategory(page = 1) {
      try {
        this.supplierCategories = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchSuplierCategory({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const supplierCategorie of data) {
              this.supplierCategories.push({ ...supplierCategorie });
            }
          } else {
            console.error(
              'No se pudieron obtener los proveedores por categorias',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error(
          'Error al obtener los proveedores por categorias:',
          error
        );
        this.loading = false;
      }
    },
    async save() {
      await createSupplierCategory(
        this.supplierCategory.dato,
        this.supplierCategory.id_institucion_educativa
      );
      await this.getPaginatedSuplierCategory(1);
      /*this.supplierCategories = await fetchSupplierCategoriesByEI(
        this.institutionId
      );*/
      this.clear();
    },
    clear() {
      this.supplierCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.supplierCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    async updateInline(item) {
      this.supplierCategory = { ...item };
      await updateSupplierCategory(
        this.supplierCategory.id,
        this.supplierCategory.dato,
        this.institutionId
      );
      await this.getPaginatedSuplierCategory(1);
      /*this.supplierCategories = await fetchSupplierCategoriesByEI(
        this.institutionId
      );*/
      this.clearInline();
    },
    async deleteInline(itemId) {
      await deleteSupplierCategory(itemId);
      await this.getPaginatedSuplierCategory(1);
      /*this.supplierCategories = await fetchSupplierCategoriesByEI(
        this.institutionId
      );*/
      this.clearInline();
    },
  },
};
