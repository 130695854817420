import { mapState, mapMutations } from 'vuex';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import {
  fetchSatCodeByEI,
  fetchSubCategorys,
  updateSubCategory,
  createSubcategory,
  deleteSubCategory,
  fetchCategoryProductByEI,
} from '../../helpers/KnProductsOption';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnTextField,
    KnDocItem,
    KnSelect,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      subcategory: {
        id: 'NA', // No asignado
        dato: null,
        id_categoria: null,
        id_codigo_sat: null,
        id_autor: null,
        id_institucion_educativa: null,
      },
      subcategories: [],
      categories: [],
      satCodes: [],
      toUpdate: false,
      imageFile: null,
      idImage: null,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedSubCategorys(1);
    this.subcategory.id_autor = this.userData.id;
    this.subcategory.id_institucion_educativa = this.institutionId;
    this.categories = await fetchCategoryProductByEI(this.institutionId); //this.fetchResultsByEI('productos', 'categoria-producto', this.institutionId)
    this.satCodes = await fetchSatCodeByEI(this.institutionId); //this.fetchResultsByEI('productos', 'codigos-sat', this.institutionId)
    //this.subcategories = await fetchSubCategorysByEI(this.institutionId); //this.fetchResultsByEI('productos', 'subcategoria-producto', this.institutionId)
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      //console.log('this.objectHasNulls(this.subcategory) || this.imageFile === null', this.objectHasNulls(this.subcategory) || this.imageFile === null);
      return this.objectHasNulls(this.subcategory);
    },
    activeCategories() {
      if (this.categories && this.categories.results) {
        return this.categories.results.filter(
          (category) => category.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeSATCodes() {
      if (this.satCodes && this.satCodes.results) {
        return this.satCodes.results.filter(
          (sat) => sat.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedSubCategorys(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedSubCategorys(1);
        this.subcategory.id_autor = this.userData.id;
        this.subcategory.id_institucion_educativa = this.institutionId;
        this.categories = await fetchCategoryProductByEI(this.institutionId); //this.fetchResultsByEI('productos', 'categoria-producto', this.institutionId)
        this.satCodes = await fetchSatCodeByEI(this.institutionId);
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getPaginatedSubCategorys(page = 1) {
      try {
        this.subcategories = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchSubCategorys({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const subcategory of data) {
              this.subcategories.push({ ...subcategory });
            }
          } else {
            console.error('No se pudieron obtener las sub-categorias', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las sub-categorias:', error);
        this.loading = false;
      }
    },
    async save() {
      if (this.toUpdate) {
        if (this.imageFile === undefined || this.imageFile == null) {
          await updateSubCategory(
            this.subcategory.id,
            this.idImage,
            this.subcategory.dato,
            this.subcategory.id_categoria,
            this.subcategory.id_codigo_sat,
            this.subcategory.id_institucion_educativa,
            this.subcategory.id_autor
          );
          await this.getPaginatedSubCategorys(1);
        } else {
          await updateSubCategory(
            this.subcategory.id,
            this.imageFile.file,
            this.subcategory.dato,
            this.subcategory.id_categoria,
            this.subcategory.id_codigo_sat,
            this.subcategory.id_institucion_educativa,
            this.subcategory.id_autor
          );
        }
        this.toUpdate = false;
        await this.getPaginatedSubCategorys(1);
      } else {
        if (this.imageFile === undefined || this.imageFile == null) {
          await createSubcategory(
            this.imageFile,
            this.subcategory.dato,
            this.subcategory.id_categoria,
            this.subcategory.id_codigo_sat,
            this.subcategory.id_institucion_educativa,
            this.subcategory.id_autor
          );
          this.clear();
          await this.getPaginatedSubCategorys(1);
        } else {
          await createSubcategory(
            this.imageFile.file,
            this.subcategory.dato,
            this.subcategory.id_categoria,
            this.subcategory.id_codigo_sat,
            this.subcategory.id_institucion_educativa,
            this.subcategory.id_autor
          );
          this.clear();
          await this.getPaginatedSubCategorys(1);
        }
      }
      this.clear();
    },
    clear() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        id_categoria: null,
        id_codigo_sat: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.imageFile = null;
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clearInline() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        id_categoria: null,
        id_codigo_sat: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId,
      };
      this.imageFile = null;
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.subcategory = { ...item };
      this.subcategory.id_categoria = item.categoria.id;
      this.subcategory.id_codigo_sat = item.codigo_sat.id;
      this.subcategory.id_institucion_educativa = item.institucion_educativa.id;
      if (item.imagen !== undefined && item.imagen !== null) {
        this.imageFile = item.imagen.imagen;
        this.idImage = item.imagen.id;
      }
      this.toUpdate = true;
    },
    async deleteInline(itemId) {
      await deleteSubCategory(itemId);
      await this.getPaginatedSubCategorys(1);
      this.clearInline();
    },
    saveImage(obj) {
      this.imageFile = obj;
    },
    clearImage() {
      this.imageFile = null;
    },
  },
};
