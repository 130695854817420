import { api } from '@/api/axios-base'
import { getItem } from "@/api/api-methods";

// estatus ingresos
export const fetchIncomeStatus = async ({
    dato = null,
    institutionId = null,
    autor = null,
    systemStatus = null,
    limit = null,
    offset = 0,
  }) => {
    try {
      const institutionFilter = institutionId
        ? `institucion_educativa=${institutionId}`
        : "";
      const datoFilter = dato ? `&dato=${dato}` : "";
      const autorFilter = autor !== null ? `&autor=${autor}` : "";
      const systemStatusFilter =
        systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
      const limitFilter = limit !== null ? `&limit=${limit}` : "";
      const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
      const response = await getItem(
        "/app-administracion/filters/estatus-ingreso?".concat(
          institutionFilter,
          datoFilter,
          autorFilter,
          systemStatusFilter,
          limitFilter,
          offsetFilter
        )
      );
      const { results, count, e } = response;
      if (e) {
        return {
          ok: false,
          message: "No se pudieron obtener los egresos por Sub-categoria. " + e,
          count: 0,
        };
      }
      // console.log('Estatus', results)
      return { ok: true, data: results, count: count };
    } catch (error) {
      console.error("Error al obtener los egresos por Sub-categoria. ", error);
      return {
        ok: false,
        message: "Error al intentar obtener los egresos por Sub-categoria. " + error,
        count: 0,
      };
    }
  };
/*export const fetchIncomeStatusByEI = async (institutionId) => {
    try {
        const response = await api.get(`/app-administracion/filters/estatus-ingreso?institucion_educativa=${institutionId}`)
        const responseData = await response.data
        if (response.status === 200) return responseData;
    } catch (error) {
        console.error('Error al intentar obtener estatus de ingreso por institucion educativa', error);
    }
};*/
export const createIncomeStatus = async (incomeStatusData, incomeStatusAutor, incomeStatusIE) => {
    try {
        const response = await api.post('/app-administracion/estatus-ingreso', {
            dato: incomeStatusData,//this.incomeStatus.dato,
            autor: incomeStatusAutor, //this.incomeStatus.id_autor,
            institucion_educativa: incomeStatusIE//this.incomeStatus.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
            console.error('Ocurrió un error, no se pudo crear el estatus de ingreso.')
        }
    } catch (error) {
        console.error('Error al intentar crear estatus de ingreso', error)
        console.error('Error al intentar crear estatus de ingreso')
    }
};
export const updateIncomeStatus = async (incomeStatusId, incomeStatusData, incomeStatusAutor, incomeStatusIE) => {
    try {
        const response = await api.put(`/app-administracion/estatus-ingreso/${incomeStatusId}`, {
            dato: incomeStatusData,//this.incomeStatus.dato,
            autor: incomeStatusAutor, //this.incomeStatus.id_autor,
            institucion_educativa: incomeStatusIE//this.incomeStatus.id_institucion_educativa
        }
        );
        const responseData = await response.data
        if (responseData.errors) {
            console.error('Ocurrió un error, no se pudo actualizar el estatus del ingreso')
        } else {
            return responseData
        }
    } catch (error) {
        console.error('Error al intentar actualizar estatus de ingreso', error);
    }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteIncomeStatus = async (typeId) => {
    try {
        const response = await api.delete(`/app-administracion/estatus-ingreso/${typeId}`);
        const responseData = await response.data
        if (responseData.errors) {
            console.error('Ocurrió un error, no se pudo eliminar el estatus del ingreso')
        }
    } catch (error) {
        console.error('Error al intentar eliminar estatus de ingreso', error);
    }
};

//Categoria ingresos
export const fetchIncomeCategory = async ({
    dato = null,
    institutionId = null,
    autor = null,
    systemStatus = null,
    limit = null,
    offset = 0,
  }) => {
    try {
      const institutionFilter = institutionId
        ? `institucion_educativa=${institutionId}`
        : "";
      const datoFilter = dato ? `&dato=${dato}` : "";
      const autorFilter = autor !== null ? `&autor=${autor}` : "";
      const systemStatusFilter =
        systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
      const limitFilter = limit !== null ? `&limit=${limit}` : "";
      const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
      const response = await getItem(
        "/app-administracion/filters/categoria-ingreso?".concat(
          institutionFilter,
          datoFilter,
          autorFilter,
          systemStatusFilter,
          limitFilter,
          offsetFilter
        )
      );
      const { results, count, e } = response;
      if (e) {
        return {
          ok: false,
          message: "No se pudieron obtener los egresos por Sub-categoria. " + e,
          count: 0,
        };
      }
      // console.log('Estatus', results)
      return { ok: true, data: results, count: count };
    } catch (error) {
      console.error("Error al obtener los egresos por Sub-categoria. ", error);
      return {
        ok: false,
        message: "Error al intentar obtener los egresos por Sub-categoria. " + error,
        count: 0,
      };
    }
  };
/*export const fetchIncomeCategoryByEI = async (institutionId) => {
    try {
        const response = await api.get(`/app-administracion/filters/categoria-ingreso?institucion_educativa=${institutionId}`)
        const responseData = await response.data
        if (response.status === 200) return responseData;
    } catch (error) {
        console.error('Error al intentar obtener categorias de ingreso por institucion educativa', error);
    }
};*/
export const createIncomeCategory = async (incomeCategoryName, incomeCategoryAutor, incomeCategoryIE) => {
    try {
        const response = await api.post('/app-administracion/categoria-ingreso', {
            dato: incomeCategoryName, //this.incomeCategory.dato,
            autor: incomeCategoryAutor, //this.incomeCategory.id_autor,
            institucion_educativa: incomeCategoryIE//this.incomeCategory.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.errors) {
            console.error('Ocurrió un error, no se pudo crear la categoria de ingreso.')
        }
    } catch (error) {
        console.error('Error al intentar crear categoria de ingreso', error)
        console.error('Error al intentar crear categoria de ingreso')
    }
};
export const updateIncomeCategory = async (incomeCategoryId, incomeCategoryName, incomeCategoryAutor, incomeCategoryIE) => {
    try {
        const response = await api.put(`/app-administracion/categoria-ingreso/${incomeCategoryId}`, {
            dato: incomeCategoryName, //this.incomeCategory.dato,
            autor: incomeCategoryAutor, //this.incomeCategory.id_autor,
            institucion_educativa: incomeCategoryIE//this.incomeCategory.id_institucion_educativa
        }
        );
        const responseData = await response.data
        if (responseData.errors) {
            console.error('Ocurrió un error, no se pudo actualizar la categoria de ingreso')
        }
    } catch (error) {
        console.error('Error al intentar actualizar categoria de ingreso', error);
    }
};
// Los deletes no eliminan los registros, cambian el estatus a False
export const deleteIncomeCategory = async (typeId) => {
    try {
        const response = await api.delete(`/app-administracion/categoria-ingreso/${typeId}`);
        const responseData = await response.data
        if (responseData.errors) {
            console.error('Ocurrió un error, no se pudo eliminar la categoria de ingreso')
        }
    } catch (error) {
        console.error('Error al intentar eliminar categoria de ingreso', error);
    }
};